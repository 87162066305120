import { DBObject } from './DBObject'
import { IDB } from './IDB'
import { PassageDocument } from './PassageDocument'

type ObjectWithDocuments = {
    db: IDB
    documents: PassageDocument[]
}

export const createDocumentObject = ({
    id,
    parent,
    title
}: {
    id: string
    parent: ObjectWithDocuments
    title: string
}) => {
    const { db, documents } = parent
    const document = new PassageDocument(id, db)
    document.title = title
    document.rank = DBObject.getNextRank(documents)
    return document
}

export const addDocumentObject = async ({
    parent,
    passageDocument,
    useExistingModDate,
    useExistingModBy
}: {
    parent: ObjectWithDocuments
    passageDocument: PassageDocument
    useExistingModDate?: boolean
    useExistingModBy?: boolean
}) => {
    await parent.db.put(passageDocument.toDocument(useExistingModDate, useExistingModBy))

    const addedDocument = parent.documents.find((document) => document._id === passageDocument._id)
    if (!addedDocument) {
        throw new Error('Could not find document we just added')
    }

    return addedDocument
}
