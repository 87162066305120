import { createContext, FC, useCallback, useContext, useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'

import { avttVersion } from './slttAvtt'
import { RefRange, refToBookId, refToChapterId } from '../../resources/RefRange'
import { Option } from '../projectSettings/projectResources/MultiSelect'

// force re-caching whenever avttVersion changes
const BIBLE_PERICOPES_PATH = `${process.env.REACT_APP_RESOURCE_URL}/public/SRV/exegeticalResources/pericopes.json?avttVersion=${avttVersion}`

type PericopeType = {
    id: string
    title: string
}

type Pericope = {
    title: string
    startRef: string
    endRef: string
}

type BookPericopes = {
    [bookId: string]: Pericope[]
}

export type BiblePericopes = {
    [id: string]: BookPericopes
}

const pericopesInRef = ({
    pericopes,
    startRef,
    endRef
}: {
    pericopes: Pericope[]
    startRef: string
    endRef: string
}) => {
    const fullEndRef = endRef.length === 6 ? `${endRef}999` : endRef
    return pericopes.filter(
        (pericope) =>
            (pericope.startRef >= startRef && pericope.endRef <= fullEndRef) ||
            refToChapterId(pericope.startRef) === refToChapterId(startRef)
    )
}

const getPericopes = ({
    biblePericopes,
    selectedPericopeType,
    refBookId,
    references,
    passageName
}: {
    biblePericopes: BiblePericopes | undefined
    selectedPericopeType: Option<string>[]
    refBookId: string
    references: RefRange[]
    passageName: string
}) => {
    if (biblePericopes && selectedPericopeType.length && biblePericopes[selectedPericopeType[0].value][refBookId]) {
        return pericopesInRef({
            pericopes: biblePericopes[selectedPericopeType[0].value][refBookId],
            startRef: references[0].startRef,
            endRef: references[0].endRef
        }).map(({ title, startRef, endRef }) => {
            const label = passageName ? `${passageName} ${title}` : title
            const value = `${startRef}-${endRef}`
            return { label, value }
        })
    }
    return []
}

interface BiblePericopesContextValue {
    biblePericopes?: BiblePericopes
    pericopeTypes: PericopeType[]
    getPericopesByReference: (
        references: RefRange[],
        selectedPericopeType: Option<string>[],
        passageName: string
    ) => Option<string>[]
    pericopeTypeOptions: (references: RefRange[]) => Option<string>[]
    isLoading: boolean
    isError: boolean
}

const BiblePericopesContext = createContext<BiblePericopesContextValue | undefined>(undefined)

export const BiblePericopesProvider: FC = ({ children }) => {
    const { t } = useTranslation()
    const [biblePericopes, setBiblePericopes] = useState<BiblePericopes>()
    const [pericopeTypes, setPericopeTypes] = useState<PericopeType[]>([])
    const [isLoading, setIsLoading] = useState(true)
    const [isError, setIsError] = useState(false)

    useEffect(() => {
        const fetchBiblePericopes = async () => {
            try {
                const response = await fetch(BIBLE_PERICOPES_PATH)

                if (!response.ok) {
                    throw Error(`${response.url}: ${response.statusText}`)
                }

                const pericopes = (await response.json()) as BiblePericopes
                if (pericopes && Object.keys(pericopes).length) {
                    // t('publishedBibles')
                    const types = Object.keys(pericopes).map((id) => {
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        return { id, title: t(id) }
                    }) as PericopeType[]
                    setPericopeTypes(types)
                    setBiblePericopes(pericopes)
                }
            } catch (err) {
                console.error(err)
                setIsError(true)
            }
            setIsLoading(false)
        }

        fetchBiblePericopes()
    }, [t])

    const getPericopesByReference = useCallback(
        (references: RefRange[], selectedPericopeType: Option<string>[], passageName: string) => {
            if (isLoading) {
                return []
            }

            const refBookId = refToBookId(references[0]?.startRef || '')

            return getPericopes({
                biblePericopes,
                selectedPericopeType,
                refBookId,
                references,
                passageName
            })
        },
        [biblePericopes, isLoading]
    )

    const pericopeTypeOptions = useCallback(
        (references: RefRange[]) => {
            const srvPericopes = getPericopesByReference(
                references,
                [{ label: 'passage per Study Guide', value: 'srvPericopes' }],
                ''
            )
            return pericopeTypes.map(({ id, title }) => ({
                label: title,
                value: id,
                disabled: !references.length || (srvPericopes.length === 0 && id === 'srvPericopes')
            }))
        },
        [pericopeTypes, getPericopesByReference]
    )

    return (
        <BiblePericopesContext.Provider
            // eslint-disable-next-line react/jsx-no-constructed-context-values
            value={{ biblePericopes, pericopeTypes, isError, isLoading, getPericopesByReference, pericopeTypeOptions }}
        >
            {children}
        </BiblePericopesContext.Provider>
    )
}

export const useBiblePericopes = () => {
    const context = useContext(BiblePericopesContext)
    if (!context) {
        throw new Error('useBiblePericopes must be used within a BiblePericopesProvider')
    }
    return context
}
