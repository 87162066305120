import {
    useAuthenticator,
    useTheme,
    Heading,
    CheckboxField,
    PasswordField,
    SelectField,
    TextField
} from '@aws-amplify/ui-react'
import { t as tFunction } from 'i18next'
import { useTranslation } from 'react-i18next'

import { isAllowedDomainOrSubdomain, isValidDisplayNameCharacters, isValidDisplayNameLength } from '../../utils/Helpers'
import { LocaleOptions } from '../LocaleSelector'

const { REACT_APP_ALLOW_SIGN_UP_EMAIL_DOMAINS = '', REACT_APP_TERMS_AND_CONDITIONS_URL } = process.env

const allowedEmailDomains = REACT_APP_ALLOW_SIGN_UP_EMAIL_DOMAINS
    ? REACT_APP_ALLOW_SIGN_UP_EMAIL_DOMAINS.split(',')
    : []

interface FormData {
    email: string
    password: string
    ['custom:projectDisplayName']: string
    acknowledgement: string
}

export const validateCustomSignUp = async (formData: FormData) => {
    const error: any = {}
    const emailRegexValidator = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

    if (!emailRegexValidator.test(formData.email)) {
        error.email = tFunction('emailInvalidMessage')
    }

    if (allowedEmailDomains.length && !isAllowedDomainOrSubdomain(formData.email, allowedEmailDomains)) {
        error.email = tFunction('signUpErrorInvalidEmailDomain')
    }

    const passwordLength = formData.password.length
    if (passwordLength < 8 || passwordLength > 256) {
        error.password = tFunction('Too short or long')
    }

    const displayNameField = 'custom:projectDisplayName'
    const displayName = formData[displayNameField]
    if (!isValidDisplayNameLength(displayName)) {
        error[displayNameField] = tFunction('Too short or long')
    } else if (!isValidDisplayNameCharacters(displayName)) {
        error[displayNameField] = tFunction('Invalid characters')
    }

    if (!formData.acknowledgement) {
        error.acknowledgement = tFunction('signUpErrorNoAcknowledgement')
    }

    return error
}

const Header = () => {
    const { tokens } = useTheme()
    const { t } = useTranslation()

    return (
        <Heading padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`} level={4} translate="no">
            {t('signUpHeader')}
        </Heading>
    )
}

const TermsAndConditions = () => {
    const { t } = useTranslation()

    const html = t('signUpTermsAndConditionsLabel', {
        url: ` <a href="${REACT_APP_TERMS_AND_CONDITIONS_URL}" target="_blank" rel="noopener noreferrer">${t(
            'signUpTermsAndConditionsTitle'
        )}</a>`
    })
    // eslint-disable-next-line react/no-danger
    return <div dangerouslySetInnerHTML={{ __html: html }} />
}

const FormFields = () => {
    const { validationErrors } = useAuthenticator()
    const { t } = useTranslation()

    return (
        <>
            <TextField
                label=""
                placeholder={t('signUpUsernameLabel')}
                name="email"
                type="email"
                required
                errorMessage={validationErrors.email as string}
                hasError={!!validationErrors.email}
            />
            <PasswordField
                label=""
                placeholder={t('signUpPasswordLabel')}
                name="password"
                descriptiveText={t('signUpPasswordDescription')}
                autoComplete="new-password"
                enterKeyHint={undefined}
                required
                errorMessage={validationErrors.password as string}
                hasError={!!validationErrors.password}
            />
            <SelectField label="" name="custom:languageCode" descriptiveText={t('signUpLanguageLabel')} required>
                <LocaleOptions />
            </SelectField>
            <TextField
                label=""
                placeholder={t('signUpProjectLabel')}
                name="custom:projectDisplayName"
                required
                errorMessage={validationErrors['custom:projectDisplayName'] as string}
                hasError={!!validationErrors['custom:projectDisplayName']}
            />
            <CheckboxField
                name="acknowledgement"
                value="yes"
                label={<TermsAndConditions />}
                enterKeyHint={undefined}
                errorMessage={validationErrors.acknowledgement as string}
                hasError={!!validationErrors.acknowledgement}
            />
            <TextField placeholder="" label="" name="custom:referer" hidden defaultValue={window.location.origin} />
        </>
    )
}

export const SignUp = { Header, FormFields }
