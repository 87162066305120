import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { Root } from '../../models3/Root'
import { IconButton, SlttHelp } from '../utils/Buttons'
import { displayError, systemError } from '../utils/Errors'
import { PassageSegmentsIcon, PassageSegmentsIconVariant } from '../utils/Icons'

export const SegmentBoundaryButton = observer(({ rt }: { rt: Root }) => {
    const { t } = useTranslation()

    const { passage, passageVideo, passageSegment, iAmInterpreter, currentTime, isIdle, timeline } = rt

    const closeSegment = passageVideo?.findSegmentAtStartTime(currentTime)

    const deletePassageSegment = async () => {
        if (!passageVideo || !closeSegment) {
            return
        }

        try {
            await passageVideo.removeSegment(closeSegment._id)
            rt.resetCurrentTime(closeSegment.time)
        } catch (err) {
            displayError(err)
        }
    }

    const createPassageSegment = async () => {
        if (!passage || !passageVideo || !passageSegment || passageSegment.isPatched) {
            return
        }

        try {
            const position = passageVideo.timeToPosition(passage, rt.currentTime)
            await passageVideo.addSegment({ position })
            rt.resetCurrentTime(rt.currentTime) // will force current segment to be set
        } catch (err) {
            systemError(err)
        }
    }

    const getSegmentIconVariant = (): PassageSegmentsIconVariant => {
        if (!passageVideo || !passageSegment || !iAmInterpreter) {
            return 'disabled'
        }

        if (!closeSegment) {
            const enableSegmentCreate = isIdle && !passageSegment.isPatched && !timeline.selectionPresent()
            return enableSegmentCreate ? 'create' : 'disabled'
        }

        const segments = passageVideo.getVisibleBaseSegments()
        const index = segments.findIndex((s) => s._id === closeSegment._id)
        const isPreviousSegmentPatched = index > 0 ? segments[index - 1].isPatched : false
        const isFirstSegment = closeSegment._id === segments[0]._id
        const enableSegmentDelete =
            isIdle &&
            !isFirstSegment &&
            !closeSegment.isPatched &&
            !isPreviousSegmentPatched &&
            closeSegment.isVisible()
        return enableSegmentDelete ? 'delete' : 'disabled'
    }
    const segmentIconVariant = getSegmentIconVariant()

    const passageSegmentButtonOnClick = () => {
        if (segmentIconVariant === 'disabled') {
            return
        }

        if (segmentIconVariant === 'create') {
            createPassageSegment()
        }

        deletePassageSegment()
    }

    return (
        <SlttHelp
            id={segmentIconVariant === 'delete' ? 'segments-delete' : 'segments-create'}
            tooltip={segmentIconVariant === 'delete' ? t('recordingDeleteSegment') : t('recordingCreateNewSegment')}
            place="bottom"
        >
            <IconButton
                enabled={segmentIconVariant !== 'disabled'}
                onClick={passageSegmentButtonOnClick}
                contents={
                    <PassageSegmentsIcon
                        className="sl-create-passage-segment"
                        style={{ verticalAlign: 'middle' }}
                        variant={segmentIconVariant}
                    />
                }
            />
        </SlttHelp>
    )
})
