import { useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'

import { OralTranscriber } from './OralTranscriber'
import { useOnlineStatus } from '../../app/OnlineStatusContext'
import { useAppRoot } from '../../app/RootContext'
import { Option } from '../../projectSettings/projectResources/MultiSelect'
import Select from '../../select/Select'

export const OralTranscriberMultiSelect = () => {
    const { isOnline } = useOnlineStatus()
    const { rt } = useAppRoot()
    const { t } = useTranslation()

    const defaultTranscriberLanguage = rt?.getDefault('oralBackTranslationLanguage')
    const [transcriberLanguage, setTranscriberLanguage] = useState<Option<string>[]>([])
    const supportedLanguages = OralTranscriber.supportedLanguages
    supportedLanguages[0].label = t('Do not automatically transcribe')

    useEffect(() => {
        const initialLanguage = isOnline
            ? supportedLanguages.find((option) => option.value === defaultTranscriberLanguage) || supportedLanguages[0]
            : supportedLanguages[0]

        setTranscriberLanguage([initialLanguage])
    }, [isOnline, defaultTranscriberLanguage, supportedLanguages])

    const saveTranscriberLanguage = (selected: Option<string>[]) => {
        if (!selected.length) {
            return // this can happen when user selects the current value again
        }

        rt?.setDefault('oralBackTranslationLanguage', selected[0].value)
        setTranscriberLanguage(selected)
    }

    return (
        <Select
            className="multi-select"
            isDisabled={!isOnline}
            onChange={(selected) => saveTranscriberLanguage(selected ? [selected] : [])}
            options={supportedLanguages}
            value={isOnline ? transcriberLanguage[0] : [supportedLanguages[0]]}
        />
    )
}
