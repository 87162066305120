/* eslint-disable react/no-array-index-key */

import { FC, useState } from 'react'

import { observer } from 'mobx-react'
import { Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import { Project } from '../../../models3/Project'
import { getAllSegmentStatusMarkers } from '../../segments/SegmentStatus'
import { CancelButton, OKButton } from '../../utils/Buttons'
import { displayError } from '../../utils/Errors'
import { PassageSegmentsIcon } from '../../utils/Icons'
import LabeledFieldWithIcon from '../../utils/LabeledFieldWithIcon'
import { SimpleTextInput } from '../../utils/TextInput'

import '../ProjectSettings.css'

interface SegmentStatusSettingsEditorProps {
    marker: JSX.Element
    label: string
    closeEditor: () => void
    save: (label: string) => void
}

const SegmentStatusSettingsEditor: FC<SegmentStatusSettingsEditorProps> = observer(
    ({ marker, label, closeEditor, save }) => {
        const { t } = useTranslation()
        const [draftLabel, setDraftLabel] = useState(label)

        function saveValues() {
            save(draftLabel)
            closeEditor()
        }

        return (
            <Modal show onHide={closeEditor} backdrop="static">
                <Modal.Header>
                    <Modal.Title>
                        <span className="note-type-header">
                            {t('editSegmentStatusMarker')}
                            <span className="note-type-header-marker">{marker}</span>
                        </span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <LabeledFieldWithIcon
                        title={t('Label')}
                        iconName="fa-tag"
                        field={<SimpleTextInput value={draftLabel} setValue={setDraftLabel} errorMessage="" />}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <div className="note-marker-color-modal-buttons button-row">
                        <OKButton
                            enabled
                            onClick={saveValues}
                            buttonClassName=""
                            className="ok-button"
                            tooltip={t('Save changes')}
                        />
                        <CancelButton
                            enabled
                            onClick={() => closeEditor()}
                            className="cancel-button"
                            tooltip={t('Cancel editing')}
                        />
                    </div>
                </Modal.Footer>
            </Modal>
        )
    }
)

interface SegmentStatusPreferencesProps {
    project: Project
    iconClassName: string
}

export const SegmentStatusPreferences: FC<SegmentStatusPreferencesProps> = observer(({ project, iconClassName }) => {
    const { t } = useTranslation()
    const { segmentStatusLabels } = project
    const [editingIndex, setEditingIndex] = useState(-1)

    async function saveValues(label: string, index: number) {
        const newLabels = segmentStatusLabels.slice()
        newLabels[index] = label
        try {
            await project.setSegmentStatusLabels(newLabels)
        } catch (error) {
            displayError(error)
        }
    }

    const markerShapes = getAllSegmentStatusMarkers({
        className: 'note-bar-marker small-note-marker fa-fw'
    })

    return (
        <div className="project-preferences-row">
            <PassageSegmentsIcon
                className={iconClassName}
                style={{ padding: '2px' }}
                tooltip={t('segmentStatusMarkers')}
                variant="create"
            />
            {markerShapes.map((marker, i) => (
                <button
                    key={i}
                    type="button"
                    className="wraparound-button note-marker-type-edit-button"
                    style={{ backgroundColor: editingIndex === i ? 'lightgray' : '' }}
                    title={t('editSegmentStatusMarker')}
                    onClick={() => setEditingIndex(i)}
                >
                    <span style={{ marginRight: segmentStatusLabels[i] ? '5px' : '0px' }}>{marker}</span>
                    {segmentStatusLabels[i]}
                </button>
            ))}
            {editingIndex > -1 && (
                <SegmentStatusSettingsEditor
                    {...{
                        marker: markerShapes[editingIndex],
                        label: segmentStatusLabels[editingIndex],
                        save: (newLabel) => saveValues(newLabel, editingIndex),
                        closeEditor: () => setEditingIndex(-1)
                    }}
                />
            )}
        </div>
    )
})
