import _ from 'underscore'

import { AudioClip } from './AudioClip'
import { BiblicalTermMarker } from './BiblicalTermMarker'
import { DBObject } from './DBObject'
import { FfmpegParameters } from './FfmpegParameters'
import { findMember, findMemberIndex, Member } from './Member'
import { Passage } from './Passage'
import { PassageDocument } from './PassageDocument'
import { PassageGloss } from './PassageGloss'
import { PassageHighlight } from './PassageHighlight'
import { PassageNote } from './PassageNote'
import { PassageNoteItem } from './PassageNoteItem'
import { PassageSegment, PassageSegmentApproval } from './PassageSegment'
import { PassageSegmentDocument } from './PassageSegmentDocument'
import { PassageSegmentGloss } from './PassageSegmentGloss'
import { PassageSegmentLabel } from './PassageSegmentLabel'
import { PassageSegmentTranscription } from './PassageSegmentTranscription'
import { PassageVideo } from './PassageVideo'
import { Portion } from './Portion'
import { IVideoCacheAcceptor, Project } from './Project'
import { ProjectImage } from './ProjectImage'
import { ProjectMessage } from './ProjectMessage'
import { ProjectPlan } from './ProjectPlan'
import { ProjectStage } from './ProjectStage'
import { ProjectTask } from './ProjectTask'
import { ProjectTerm } from './ProjectTerm'
import { ReferenceMarker } from './ReferenceMarker'
import { ReviewProject } from './ReviewProject'
import { ReviewProjectGeneralQuestion } from './ReviewProjectGeneralQuestion'
import { ReviewProjectPassageRecording } from './ReviewProjectPassageRecording'
import { ReviewProjectProfileAttribute } from './ReviewProjectProfileAttribute'
import { SignVideo } from './SignVideo'
import { TargetGloss } from './TargetGloss'
import { normalizeUsername } from './Utils'
import { systemError } from '../components/utils/DynamicErrors'
import { fmt } from '../components/utils/Fmt'
import { getBookNames } from '../resources/bookNames'
import { RefRange } from '../resources/RefRange'
import { DbObjectIdPrefix, TextHistoryEntry } from '../types'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const log = require('debug')('sltt:DBAcceptor')
// eslint-disable-next-line @typescript-eslint/no-var-requires
const dbg = require('debug')('slttdbg:DBAcceptor')

/* REMINDER
   
   If you create a new object the contains a url for a media object uploaded to S3,
   you must include code like this to trigger the video cache to upload/down the video.

        if (doc.videoUrl) {
            this.videoCacheAcceptor(doc.videoUrl)
                .catch(systemError)
        }
 */

// _rev = This is a non persisted attribute. We increment it to force views that
// depend on any aspect of a model object to re-render when that object changes.
// This is probably overkill but the model does not change very often (a few times
// time an hour on the average) and extra re-renders are less problematic than
// too few re-renders.

class Builder {
    constructor(
        public factory: any,
        public acceptor: (target: any, env: any[], doc: any) => void,
        public attr: string
    ) {}
}

// If attribute is present and different than current value in target, accept new value
function accept(dbObject: DBObject, doc: any, attributeName: string) {
    const target = dbObject as any
    const value = doc[attributeName]

    if (value === undefined || target[attributeName] === value) {
        return
    }

    target[attributeName] = value
}

const getUpdatedTextHistory = (textHistory: TextHistoryEntry[], doc: any) => {
    const { text, modBy = '', modDate = '' } = doc
    if (
        (!textHistory.length && text === '') ||
        textHistory.some((v) => v.text === text && v.modBy === modBy && v.modDate === modDate)
    ) {
        return textHistory
    }

    return [...textHistory, { text, modBy, modDate }]
}

export class DBAcceptor {
    private removedItems: Map<string, DBObject> = new Map()

    constructor(
        public project: Project,
        public videoCacheAcceptor: IVideoCacheAcceptor,
        public model: number,
        private allowInvalidIds = false // Useful for testing, where we don't yet generate real ids
    ) {}

    accept(doc: any, label?: string, seq?: number) {
        seq = seq ?? -1

        // We don't know how to handle any documents that specify a model newer than ours
        if (doc.model !== undefined && doc.model > this.model) {
            log('### doc with model ignored', doc)
            return
        }

        const { project } = this
        dbg(`accept`, doc)

        if (label) {
            log(label, JSON.stringify(doc, null, 4))
        }

        const parts = doc._id.split('/')

        // Ingore deprecated legacy objects, e.g. segment references
        if (parts.length > 4 && parts[4].startsWith('segRsc_')) {
            console.log('### deprecated object ignored', doc)
            return
        }

        const isProject =
            parts.length === 1 &&
            (parts[0] === 'members' ||
                parts[0] === 'project' ||
                parts[0] === 'member' ||
                parts[0] === 'projectPreferences' ||
                parts[0] === 'teamPreferences')

        if (isProject) {
            this.acceptProject(project, [], doc)
            return
        }

        // SignVideo is weird. It is a subobject of Project, but its _id has 2 parts.
        const isSignVideo = parts.length === 2 && parts[0] === 'sign'
        if (isSignVideo) {
            this.acceptTermSign(project, [], doc)
            return
        }

        // SignVideo is weird. It is a subobject of Project, but its _id has 2 parts.
        const isProjectMessage = parts.length === 2 && parts[0] === 'notify'
        if (isProjectMessage) {
            this.acceptProjectMessage(project, doc, seq)
            return
        }

        const builders = this.getBuilders(parts)

        let target: any = this.project

        // This an array of all the parent objects corresponding to the doc we have just received
        const env: any[] = [project]
        for (let i = 0; i < parts.length; ++i) {
            parts[i] = parts[i].trim()
            if (parts[i] === '') throw Error(`Empty part in path [${doc._id}]`)

            let path = parts.slice(0, i + 1).join('/')

            // Paths may have a tag (@viewedby, @task) at the end.
            // Remove it when looking for a matching object.
            path = path.split('@')[0]

            // Save item so that if it is deleted, we know which item was deleted
            const items = target[builders[i].attr]
            if (items === undefined) {
                throw Error('Could not find attributes')
            }

            const idx = _.findIndex(items, { _id: path })
            const item = idx >= 0 ? items[idx] : null

            target = this.upsert(
                target,
                builders[i].attr,
                path,
                builders[i].factory,
                doc.removed,
                i === parts.length - 1
            )

            // item was removed
            if (!target) {
                // If the parent object of the term marker is deleted, this will not get run. This
                // is fine, because if you can't access the parent, you can't access the term.
                if (item instanceof BiblicalTermMarker) {
                    this.project.acceptDeletedTermMarker(item)
                }
                for (const entry of env) {
                    if (entry._rev !== undefined) {
                        entry._rev += 1 // force rerender
                    }
                }
                return
            }

            env.push(target)
        }

        // Once we have found/created all the parent objects
        // set the attributes of the object corresponding to the doc we received
        const acceptor = builders[parts.length - 1].acceptor.bind(this)
        acceptor(target, env, doc)
    }

    // Generate list of factories, acceptors, and attributes to get to this object
    // Also valid to get to any object along the path
    private getBuilders(parts: string[]) {
        const builders: Builder[] = []
        if (parts.length === 0) {
            return builders
        }

        let part = parts[0]
        if (part.startsWith('prjImg_')) {
            builders.push(new Builder(ProjectImage, this.acceptProjectImage.bind(this), 'images'))
        } else if (part.startsWith('plan_')) {
            builders.push(new Builder(ProjectPlan, this.acceptProjectPlan.bind(this), 'plans'))
        } else if (part.startsWith('term_')) {
            builders.push(new Builder(ProjectTerm, this.acceptProjectTerm.bind(this), 'terms'))
        } else if (part.startsWith(DbObjectIdPrefix.PROJECT_DOCUMENT)) {
            builders.push(new Builder(PassageDocument, this.acceptPassageDocument.bind(this), 'documents'))
        } else if (part === 'rPrj') {
            builders.push(new Builder(ReviewProject, this.acceptReviewProject.bind(this), 'reviewProjects'))
        } else {
            builders.push(new Builder(Portion, this.acceptPortion.bind(this), 'portions'))
        }

        if (parts.length === 1) {
            return builders
        }

        part = parts[1]
        if (part.startsWith('stg_')) {
            builders.push(new Builder(ProjectStage, this.acceptProjectPlanStage.bind(this), 'stages'))
        } else if (part.startsWith('sign_')) {
            builders.push(new Builder(SignVideo, this.acceptSenseSign.bind(this), 'renderings'))
        } else if (part.startsWith('tgls_')) {
            builders.push(new Builder(TargetGloss, this.acceptTargetGloss.bind(this), 'glosses'))
        } else if (part.startsWith('rpa_')) {
            builders.push(
                new Builder(
                    ReviewProjectProfileAttribute,
                    this.acceptReviewProjectProfileAttribute.bind(this),
                    'profileAttributes'
                )
            )
        } else if (part.startsWith('rpgq_')) {
            builders.push(
                new Builder(
                    ReviewProjectGeneralQuestion,
                    this.acceptReviewProjectGeneralQuestion.bind(this),
                    'generalQuestions'
                )
            )
        } else if (part.startsWith('rppr_')) {
            builders.push(
                new Builder(
                    ReviewProjectPassageRecording,
                    this.acceptReviewProjectPassageRecording.bind(this),
                    'passageRecordings'
                )
            )
        } else {
            builders.push(new Builder(Passage, this.acceptPassage.bind(this), 'passages'))
        }

        if (parts.length === 2) {
            return builders
        }

        part = parts[2]
        if (part.startsWith('tsk_')) {
            builders.push(new Builder(ProjectTask, this.acceptProjectPlanTask.bind(this), 'tasks'))
        } else if (part.startsWith(DbObjectIdPrefix.PASSAGE_DOCUMENT)) {
            builders.push(new Builder(PassageDocument, this.acceptPassageDocument.bind(this), 'documents'))
        } else {
            builders.push(new Builder(PassageVideo, this.acceptPassageVideo.bind(this), 'videos'))
        }

        if (parts.length === 3) {
            return builders
        }

        part = parts[3]
        if (part.startsWith('seg_')) {
            builders.push(new Builder(PassageSegment, this.acceptPassageSegment.bind(this), 'segments'))
        } else if (part.startsWith('gls_')) {
            builders.push(new Builder(PassageGloss, this.acceptPassageGloss.bind(this), 'glosses'))
        } else if (part.startsWith('hgh_')) {
            builders.push(new Builder(PassageHighlight, this.acceptPassageHighlight.bind(this), 'highlights'))
        } else if (part.startsWith('ref_')) {
            builders.push(new Builder(ReferenceMarker, this.acceptPassageVideoReference.bind(this), 'references'))
        } else if (part.startsWith('btm_')) {
            builders.push(
                new Builder(BiblicalTermMarker, this.acceptBiblicalTermMarker.bind(this), 'biblicalTermMarkers')
            )
        } else {
            builders.push(new Builder(PassageNote, this.acceptPassageNote.bind(this), 'notes'))
        }

        if (parts.length === 4) {
            return builders
        }

        part = parts[4]
        if (part.startsWith('segDoc_')) {
            builders.push(
                new Builder(PassageSegmentDocument, this.acceptPassageSegmentDocument.bind(this), 'documents')
            )
        } else if (part.startsWith('segAudClip_')) {
            builders.push(new Builder(AudioClip, this.acceptAudioClip.bind(this), 'audioClips'))
        } else if (part.startsWith('segTrs_')) {
            builders.push(
                new Builder(
                    PassageSegmentTranscription,
                    this.acceptPassageSegmentTranscription.bind(this),
                    'transcriptions'
                )
            )
        } else {
            builders.push(new Builder(PassageNoteItem, this.acceptPassageNoteItem.bind(this), 'items'))
        }

        return builders
    }

    // Find or create an object with the given path.
    // If we are processing a removed object notification we do not create anything.
    // However we may still need to find higher level parents in order to locate the
    // item that is being deleted.
    upsert(
        target: any,
        attr: string,
        path: string,
        targetClass:
            | typeof ProjectImage
            | typeof Portion
            | typeof PassageDocument
            | typeof ProjectStage
            | typeof ProjectTask
            | typeof ProjectPlan
            | typeof Passage
            | typeof PassageVideo
            | typeof PassageSegment
            | typeof PassageGloss
            | typeof PassageHighlight
            | typeof ReferenceMarker
            | typeof PassageNote
            | typeof PassageNoteItem
            | typeof SignVideo
            | typeof ReviewProject
            | typeof ReviewProjectProfileAttribute,
        removed: boolean,
        lastPart: boolean
    ) {
        // Get the items that already exist on the parent correspond to the target
        const items = target[attr]
        if (items === undefined) {
            throw Error('Could not find attributes')
        }

        const idx = _.findIndex(items, { _id: path })

        const createItem = () => {
            // eslint-disable-next-line new-cap
            const item = new targetClass(path, this.project.db)
            if (targetClass === PassageVideo && lastPart && removed) {
                // Keep passage videos around in the model so that users can "undelete" them
                item.removed = true
            }
            return item
        }

        const resurrectItem = (item: DBObject) => {
            item.removed = false
            items.push(item)
            this.removedItems.delete(path)
            return item
        }

        const removeExistingItem = () => {
            items[idx].removed = true

            if (targetClass !== PassageVideo) {
                this.removedItems.set(path, items[idx])
                items.splice(idx, 1)
            }

            // Keep passage videos around in the model so that users can "undelete" them
            target._rev += 1 // force rerender
        }

        if (idx >= 0) {
            if (!lastPart) {
                return items[idx]
            }

            if (removed) {
                removeExistingItem()
                // Keep passage videos around in the model so that users can "undelete" them
                if (targetClass !== PassageVideo) {
                    return null
                }
            }

            // We must explicitly check for false because sometimes removed is undefined.
            // We should fix this.
            if (removed === false) {
                items[idx].removed = false
                target._rev += 1 // force rerender
            }

            return items[idx]
        }

        // If we received a note that an item has been removed
        // but we have not yet created part of the path that contains it,
        // do not create a parent item, just return null
        if (removed && (!lastPart || targetClass !== PassageVideo)) {
            return null
        }

        // Restore object that was previously deleted from the model
        const deletedItem = this.removedItems.get(path)
        if (deletedItem) {
            return resurrectItem(deletedItem)
        }

        const item = createItem()

        /**
         * Normally we insert new items at the end of the list.
         * For project plans however there is normally only one plan and it is the zero'th item.
         * If a new plan is created however we want that plan to replace a previously created plan.
         * Normally the only reason to create a new plan would be as some kind of bug fix
         * that required remigrating the plan.
         */
        if (targetClass === ProjectPlan) {
            items.unshift(item)
        } else {
            items.push(item)
        }

        return item
    }

    acceptProject(project: Project, env: any[], doc: any) {
        this.acceptCreationInfo(project, doc)
        if (doc.copyrightStatement !== undefined && project.copyrightStatement !== doc.copyrightStatement) {
            project.copyrightStatement = doc.copyrightStatement
        }

        this.acceptMembers(doc)
        this.acceptBookName(project, doc)
        this.acceptMember(project, [], doc)
        this.acceptProjectPreferences(project, [], doc)
        this.acceptTeamPreferences(project, [], doc)
    }

    acceptProjectPreferences(project: Project, env: any[], doc: any) {
        this.acceptCreationInfo(project, doc)
        accept(project, doc, 'displayName')
        accept(project, doc, 'text')
        accept(project, doc, 'region')
        accept(project, doc, 'projectType')
        accept(project, doc, 'overrideRecordingLayout')

        if (
            doc.segmentEditorPanelLayout !== undefined &&
            JSON.stringify(project.segmentEditorPanelLayout) !== JSON.stringify(doc.segmentEditorPanelLayout)
        ) {
            project.segmentEditorPanelLayout = doc.segmentEditorPanelLayout
        }

        if (
            doc.segmentEditorHiddenPanels !== undefined &&
            JSON.stringify([...project.segmentEditorHiddenPanels].sort()) !==
                JSON.stringify([...doc.segmentEditorHiddenPanels].sort())
        ) {
            project.segmentEditorHiddenPanels = doc.segmentEditorHiddenPanels
        }

        if (
            doc.studyTabLayout !== undefined &&
            JSON.stringify(project.studyTabLayout) !== JSON.stringify(doc.studyTabLayout)
        ) {
            const layout = [...doc.studyTabLayout]
            if (!layout.includes('passageNotes')) {
                layout.push('passageNotes')
            }
            project.studyTabLayout = layout
        }

        if (
            doc.studyTabHiddenTabs !== undefined &&
            JSON.stringify([...project.studyTabHiddenTabs].sort()) !==
                JSON.stringify([...doc.studyTabHiddenTabs].sort())
        ) {
            project.studyTabHiddenTabs = doc.studyTabHiddenTabs
        }
    }

    acceptTeamPreferences(project: Project, env: any[], doc: any) {
        this.acceptCreationInfo(project, doc)
        if (doc.noteColors !== undefined && JSON.stringify(project.noteColors) !== JSON.stringify(doc.noteColors)) {
            project.noteColors = doc.noteColors
        }

        if (doc.noteLabels !== undefined && JSON.stringify(project.noteLabels) !== JSON.stringify(doc.noteLabels)) {
            project.noteLabels = doc.noteLabels
        }

        if (
            doc.segmentStatusLabels !== undefined &&
            JSON.stringify(project.segmentStatusLabels) !== JSON.stringify(doc.segmentStatusLabels)
        ) {
            project.segmentStatusLabels = doc.segmentStatusLabels
        }

        if (doc.dateFormat !== undefined && project.dateFormat !== doc.dateFormat) {
            project.dateFormat = doc.dateFormat
        }

        if (doc.recordingCountdown !== undefined && project.recordingCountdown !== doc.recordingCountdown) {
            project.recordingCountdown = doc.recordingCountdown
        }

        if (doc.autoGainControl !== undefined && project.autoGainControl !== doc.autoGainControl) {
            project.autoGainControl = doc.autoGainControl
        }

        if (doc.compressedVideoCRF !== undefined && project.compressedVideoQuality !== doc.compressedVideoCRF) {
            project.compressedVideoQuality = doc.compressedVideoCRF
        }

        if (
            doc.compressedVideoResolution !== undefined &&
            project.compressedVideoResolution !== doc.compressedVideoResolution
        ) {
            project.compressedVideoResolution = doc.compressedVideoResolution
        }

        if (doc.maxVideoSizeMB !== undefined && project.maxVideoSizeMB !== doc.maxVideoSizeMB) {
            project.maxVideoSizeMB = doc.maxVideoSizeMB
        }

        if (doc.recordAudioOnly !== undefined) {
            // This property is not used anymore. Leaving this in so we know we cannot use this
            // property in the future to mean something else.
        }

        if (doc.isEngageEnabled !== undefined && project.isEngageEnabled !== doc.isEngageEnabled) {
            project.isEngageEnabled = doc.isEngageEnabled
        }
    }

    acceptProjectPlan(project: Project, env: any[], doc: any) {
        this.acceptCreationInfo(project, doc)

        // No fields to update at this point
        log('acceptProjectPlan', env.slice(-1)[0]._id)
    }

    acceptProjectPlanStage(stage: ProjectStage, env: any[], doc: any) {
        this.acceptCreationInfo(stage, doc)

        if (doc.name !== undefined && stage.name !== doc.name) {
            stage.name = doc.name
        }

        if (doc.rank !== undefined && stage.rank !== doc.rank) {
            stage.rank = doc.rank
        }

        log(`acceptProjectPlanStage ${doc.name}/${doc.rank}`)

        const projectPlan: ProjectPlan = env[1]
        projectPlan.stages = projectPlan.stages.slice().sort(this.sortByRank)
        projectPlan.updateIndices()
    }

    acceptProjectPlanTask(task: ProjectTask, env: any[], doc: any) {
        this.acceptCreationInfo(task, doc)

        const { name, details, rank, id } = doc
        const stageName = env[2].name
        log('acceptProjectPlanTask', fmt({ stageName, name, details, rank, id }))

        if (doc.name !== undefined && task.name !== doc.name) {
            task.name = doc.name
        }

        if (doc.details !== undefined && task.details !== doc.details) {
            task.details = doc.details
        }

        if (doc.difficulty !== undefined && task.difficulty !== doc.difficulty) {
            task.difficulty = doc.difficulty
        }

        if (doc.rank !== undefined && task.rank !== doc.rank) {
            task.rank = doc.rank
        }

        if (doc.id !== undefined && task.id !== doc.id) {
            task.id = doc.id
        }

        const projectStage: ProjectStage = env[2]
        projectStage.tasks = projectStage.tasks.slice().sort(this.sortByRank)
        projectStage.updateIndices()
    }

    acceptProjectImage(projectImage: ProjectImage, env: any[], doc: any) {
        this.acceptCreationInfo(projectImage, doc)

        if (doc.src !== undefined && projectImage.src !== doc.src) {
            projectImage.src = doc.src
        }

        if (doc.rank !== undefined && projectImage.rank !== doc.rank) {
            projectImage.rank = doc.rank
        }

        env[0].images = env[0].images.slice().sort(this.sortByRank)
    }

    acceptProjectTerm(projectTerm: ProjectTerm, env: any[], doc: any) {
        this.acceptCreationInfo(projectTerm, doc)

        if (doc.lexicalLink !== undefined && projectTerm.lexicalLink !== doc.lexicalLink) {
            projectTerm.lexicalLink = doc.lexicalLink
        }

        if (doc.isKeyTerm !== undefined && projectTerm.isKeyTerm !== doc.isKeyTerm) {
            projectTerm.isKeyTerm = doc.isKeyTerm
        }

        if (doc.notes !== undefined && projectTerm.notes !== doc.notes) {
            projectTerm.notes = doc.notes
        }

        if (env[0] instanceof Project) {
            env[0].acceptProjectTerm(projectTerm)
        }

        env[0].terms = env[0].terms.slice().sort(this.sortByRank)
    }

    acceptReviewProject(project: ReviewProject, env: any[], doc: any) {
        this.acceptCreationInfo(project, doc)

        if (doc.projectKey !== undefined && project.projectKey !== doc.projectKey) {
            project.projectKey = doc.projectKey
        }

        if (doc.projectCode !== undefined && project.projectCode !== doc.projectCode) {
            project.projectCode = doc.projectCode
        }

        if (doc.title !== undefined && project.title !== doc.title) {
            project.title = doc.title
        }

        if (doc.rank !== undefined && project.rank !== doc.rank) {
            project.rank = doc.rank
        }

        if (doc.isActive !== undefined && project.isActive !== doc.isActive) {
            project.isActive = doc.isActive
        }

        env[0].reviewProjects = env[0].reviewProjects.slice().sort(this.sortByRank)
    }

    acceptReviewProjectProfileAttribute(profileAttribute: ReviewProjectProfileAttribute, env: any[], doc: any) {
        this.acceptCreationInfo(profileAttribute, doc)

        if (doc.label !== undefined && profileAttribute.label !== doc.label) {
            profileAttribute.label = doc.label
        }

        if (doc.options !== undefined && JSON.stringify(profileAttribute.options) !== JSON.stringify(doc.options)) {
            profileAttribute.options = doc.options
        }

        if (doc.responseType !== undefined && profileAttribute.responseType !== doc.responseType) {
            profileAttribute.responseType = doc.responseType
        }

        if (doc.rank !== undefined && profileAttribute.rank !== doc.rank) {
            profileAttribute.rank = doc.rank
        }

        if (doc.isActive !== undefined && profileAttribute.isActive !== doc.isActive) {
            profileAttribute.isActive = doc.isActive
        }

        env[1].profileAttributes = env[1].profileAttributes.slice().sort(this.sortByRank)
    }

    acceptReviewProjectGeneralQuestion(generalQuestion: ReviewProjectGeneralQuestion, env: any[], doc: any) {
        this.acceptCreationInfo(generalQuestion, doc)

        if (doc.text !== undefined && generalQuestion.text !== doc.text) {
            generalQuestion.text = doc.text
        }

        if (doc.fileName !== undefined && generalQuestion.fileName !== doc.fileName) {
            generalQuestion.fileName = doc.fileName
        }

        if (doc.rank !== undefined && generalQuestion.rank !== doc.rank) {
            generalQuestion.rank = doc.rank
        }

        if (doc.isActive !== undefined && generalQuestion.isActive !== doc.isActive) {
            generalQuestion.isActive = doc.isActive
        }

        env[1].generalQuestions = env[1].generalQuestions.slice().sort(this.sortByRank)
    }

    acceptReviewProjectPassageRecording(passageRecording: ReviewProjectPassageRecording, env: any[], doc: any) {
        this.acceptCreationInfo(passageRecording, doc)

        if (doc.title !== undefined && passageRecording.title !== doc.title) {
            passageRecording.title = doc.title
        }

        if (doc.passageRecordingId !== undefined && passageRecording.passageRecordingId !== doc.passageRecordingId) {
            passageRecording.passageRecordingId = doc.passageRecordingId

            if (doc.passageRecordingId && env[0] instanceof Project) {
                env[0].recordingsWithReviews.add(doc.passageRecordingId)
            }
        }

        if (doc.fileName !== undefined && passageRecording.fileName !== doc.fileName) {
            passageRecording.fileName = doc.fileName
        }

        if (doc.duration !== undefined && passageRecording.duration !== doc.duration) {
            passageRecording.duration = doc.duration
        }

        if (doc.mimeType !== undefined && passageRecording.mimeType !== doc.mimeType) {
            passageRecording.mimeType = doc.mimeType
        }

        if (doc.reference !== undefined && passageRecording.reference !== doc.reference) {
            passageRecording.reference = doc.reference
        }

        if (doc.transcription !== undefined && passageRecording.transcription !== doc.transcription) {
            passageRecording.transcription = doc.transcription
        }

        if (doc.isActive !== undefined && passageRecording.isActive !== doc.isActive) {
            passageRecording.isActive = doc.isActive
        }

        if (doc.rank !== undefined && passageRecording.rank !== doc.rank) {
            passageRecording.rank = doc.rank
        }

        if (
            doc.timeCodes !== undefined &&
            JSON.stringify(passageRecording.timeCodes) !== JSON.stringify(doc.timeCodes)
        ) {
            passageRecording.timeCodes = doc.timeCodes
        }

        env[1].passageRecordings = env[1].passageRecordings.slice().sort(this.sortByRank)
    }

    acceptBookName(project: Project, doc: any) {
        // bbbccc is realy only bbb (no chapter number present)
        const { bbbccc, projectBookName, projectBookNames } = doc

        // Support legacy code
        if (
            bbbccc !== undefined &&
            projectBookName !== undefined &&
            Object.keys(project.bookNames).includes(bbbccc) &&
            project.bookNames[bbbccc] !== projectBookName
        ) {
            project.bookNames[bbbccc] = projectBookName
        }

        if (projectBookNames !== undefined) {
            const bookNamesMap = projectBookNames as Record<string, string>
            for (const [key, value] of Object.entries(bookNamesMap)) {
                project.bookNames[key] = value
            }
        }

        this.acceptCreationInfo(project, doc)
    }

    acceptMembers(doc: any) {
        const { project } = this

        if (!doc.members) return

        this.acceptCreationInfo(project, doc)

        // We do not allow setting an imageUrl anymore, but will still accept existing values
        project.members = doc.members.map((item: any) => new Member(item.email, item.role, item.imageUrl))
    }

    acceptMember(project: Project, env: any[], doc: any) {
        const email = normalizeUsername(doc.email ?? '')
        if (doc._removed) {
            const index = findMemberIndex(project.members, email)
            if (index >= 0) {
                this.acceptCreationInfo(project, doc)
                project.members.splice(index, 1)
            }

            return
        }

        const member = findMember(project.members, email)
        if (!member) {
            if (doc._added) {
                if (!email) {
                    log('### member to be added has no email address')
                    return
                }

                this.acceptCreationInfo(project, doc)
                project.members.push(new Member(email, doc.role || 'observer'))
            }

            return
        }

        this.acceptCreationInfo(project, doc)

        if (doc.role !== undefined && member.role !== doc.role) {
            member.role = doc.role
        }

        // We do not allow setting an imageUrl anymore, but will still accept existing values
        if (doc.imageUrl !== undefined && member.imageUrl !== doc.imageUrl) {
            member.imageUrl = doc.imageUrl
        }
    }

    acceptTermSign(project: Project, env: any[], doc: any) {
        if (doc.removed) {
            this.acceptCreationInfo(project, doc)
            delete project.signs[doc._id]
            return
        }

        this.acceptCreationInfo(project, doc)

        const signVideo = new SignVideo(doc._id, this.project.db)

        if (doc.url !== undefined && signVideo.url !== doc.url) {
            signVideo.url = doc.url

            // This call triggers an uploaded if the video has been created locally
            // but not yet uploaded.
            this.videoCacheAcceptor(doc.url).catch(systemError)
        }

        project.signs[doc._id] = signVideo
    }

    acceptProjectMessage(project: Project, doc: any, seq: number) {
        let { messages } = project

        let _parent: ProjectMessage | undefined

        if (doc.parent ?? '') {
            _parent = messages.find((n) => n._id === doc.parent)
            if (!_parent) {
                log(`### project message parent not found: ${doc.parent}`)
                return
            }

            messages = _parent.responses
        }

        if (doc.removed) {
            const i = messages.findIndex((n) => n._id === doc._id)
            if (i >= 0) {
                this.acceptCreationInfo(messages[i], doc)
                messages.splice(i, 1)
            }

            return
        }

        let newMessage = false
        let message = messages.find((n) => n._id === doc._id)
        if (!message) {
            newMessage = true
            message = new ProjectMessage(doc._id, this.project.db)
        }

        this.acceptCreationInfo(message, doc)

        if (doc.text !== undefined) {
            message.text = doc.text
        }
        if (doc.videoUrl !== undefined) {
            message.videoUrl = doc.videoUrl
        }
        if (doc.globalMessage !== undefined) {
            message.globalMessage = doc.globalMessage
        }
        if (doc.subject !== undefined) {
            message.subject = doc.subject
        }
        if (doc.parent !== undefined) {
            message.parent = doc.parent
        }
        if (doc.viewed !== undefined) {
            message.viewed = doc.viewed
        }

        // We track the seq number from the local db in order to make it easy to directly update
        // the record to set the 'viewed' attribute.
        if (message.seq !== undefined) {
            message.seq = seq
        }

        // This call triggers an uploaded if the video has been created locally
        // but not yet uploaded.
        if (doc.videoUrl) {
            this.videoCacheAcceptor(doc.videoUrl).catch(systemError)
        }

        if (newMessage) {
            messages.push(message)
        }
    }

    acceptCreationInfo(dbobject: DBObject, doc: any) {
        let { creator } = doc
        if (creator !== undefined) {
            creator = normalizeUsername(creator)
            if (dbobject.creator !== creator) {
                dbobject.creator = creator
            }
        }

        if (doc.creationDate !== undefined && dbobject.creationDate !== doc.creationDate) {
            dbobject.creationDate = doc.creationDate
        }

        if (doc.modDate !== undefined && doc.modDate !== dbobject.modDate) {
            dbobject.modDate = doc.modDate
        }

        // If modBy is not present, we don't know who modified the object, so reset to empty
        // string.
        dbobject.modBy = doc.modBy ?? ''
    }

    acceptSenseSign(sign: SignVideo, env: any[], doc: any) {
        this.acceptCreationInfo(sign, doc)
        if (doc.url !== undefined && sign.url !== doc.url) {
            sign.url = doc.url
        }

        if (doc.creator !== undefined && sign.creator !== doc.creator) {
            sign.creator = doc.creator
        }

        if (doc.creationDate !== undefined && sign.creationDate !== doc.creationDate) {
            sign.creationDate = doc.creationDate
        }

        if (doc.mimeType !== undefined && sign.mimeType !== doc.mimeType) {
            sign.mimeType = doc.mimeType
        }

        env[1].renderings = env[1].renderings.slice().sort(this.sortByRank)

        // This call triggers an uploaded if the video has been created locally
        // but not yet uploaded.
        this.videoCacheAcceptor(doc.url).catch(systemError)
    }

    acceptTargetGloss(targetGloss: TargetGloss, env: any[], doc: any) {
        this.acceptCreationInfo(targetGloss, doc)

        if (doc.lexicalLink !== undefined && targetGloss.lexicalLink !== doc.lexicalLink) {
            targetGloss.lexicalLink = doc.lexicalLink
        }

        if (doc.text !== undefined && targetGloss.text !== doc.text) {
            targetGloss.text = doc.text
        }

        if (env[0] instanceof Project) {
            env[0].acceptTargetGloss(targetGloss)
        }

        env[1].glosses = env[1].glosses.slice().sort(this.sortByRank)
    }

    acceptBiblicalTermMarker(marker: BiblicalTermMarker, env: any[], doc: any) {
        this.acceptCreationInfo(marker, doc)

        if (doc.targetGlossId !== undefined && marker.targetGlossId !== doc.targetGlossId) {
            marker.targetGlossId = doc.targetGlossId
        }

        if (doc.position !== undefined && marker.position !== doc.position) {
            marker.position = doc.position
        }

        if (env[0] instanceof Project) {
            env[0].acceptBiblicalTermMarker(marker)
        }

        env[3].biblicalTermMarkers = env[3].biblicalTermMarkers.slice().sort(this.sortByRank)

        const passage: Passage = env[2]
        const passageVideo: PassageVideo = env[3]
        this.updateBaseVideoRev(passage, passageVideo)
        passage._rev += 1 // cause passage views to re-render
    }

    acceptPortion(portion: Portion, env: any[], doc: any) {
        if (!this.allowInvalidIds && !doc._id.match(/^[0-9]{6}_[0-9]{6}$/)) {
            log('### Rejecting document with invalid portion _id.', doc)
            return
        }

        if (doc.name !== undefined && portion.name !== doc.name) {
            portion.name = doc.name
        }

        if (doc.rank !== undefined && portion.rank !== doc.rank) {
            portion.rank = doc.rank
        }

        this.acceptCreationInfo(portion, doc)

        if (doc.copiedFromId !== undefined && portion.copiedFromId !== doc.copiedFromId) {
            portion.copiedFromId = doc.copiedFromId
        }

        // Some portions got persisted with the reference field set to an empty array.
        // Ignore those and only process the reference fields which are strings.

        if (typeof doc.references === 'string') {
            // Convert old-style string references to RefRange[]
            let refRanges: RefRange[] = []
            if (doc.references === '' || doc.references[0] !== '[') {
                refRanges = RefRange.parseReferences(doc.references, 'en', getBookNames('en'))
            } else {
                const unserializedReferences = JSON.parse(doc.references)
                if (JSON.stringify(portion.references) !== unserializedReferences) {
                    refRanges = unserializedReferences.map((ref: any) => new RefRange(ref.startRef, ref.endRef))
                }
            }
            portion.references = refRanges
        }

        env[0].portions = env[0].portions.slice().sort(this.sortByRank)

        portion._rev += 1
    }

    acceptPassage(passage: Passage, env: any[], doc: any) {
        if (doc.difficulty !== undefined) {
            passage.difficulty = doc.difficulty
        }

        if (doc.recordingMediaType !== undefined) {
            passage.recordingMediaType = doc.recordingMediaType
        }

        if (doc.copiedFromId !== undefined && passage.copiedFromId !== doc.copiedFromId) {
            passage.copiedFromId = doc.copiedFromId
        }

        if (doc.name !== undefined && passage.name !== doc.name) {
            passage.name = doc.name
        }

        if (doc.rank !== undefined && passage.rank !== doc.rank) {
            passage.rank = doc.rank
        }

        this.acceptCreationInfo(passage, doc)

        if (doc.assignee !== undefined && passage.assignee !== doc.assignee) {
            passage.assignee = doc.assignee
        }

        if (doc.contentType !== undefined && passage.contentType !== doc.contentType) {
            passage.contentType = doc.contentType
        }

        if (doc.references !== undefined) {
            const docReferences = JSON.parse(doc.references)
            if (JSON.stringify(passage.references) !== JSON.stringify(docReferences)) {
                passage.references = docReferences.map((ref: any) => new RefRange(ref.startRef, ref.endRef))
            }
        }

        env[1].passages = env[1].passages.slice().sort(this.sortByRank)

        passage._rev += 1
    }

    acceptPassageDocument(passageDocument: PassageDocument, env: any[], doc: any) {
        this.acceptCreationInfo(passageDocument, doc)

        if (doc.title !== undefined && passageDocument.title !== doc.title) {
            passageDocument.title = doc.title
        }

        if (doc.text !== undefined && passageDocument.text !== doc.text) {
            passageDocument.textHistory = getUpdatedTextHistory(passageDocument.textHistory, doc)
            passageDocument.text = doc.text
        }

        // The creation date of the video may not be the same as the creation date of the pdf
        if (doc.pdfUrl !== undefined && passageDocument.pdfUrl !== doc.pdfUrl) {
            passageDocument.pdfUrl = doc.pdfUrl
            if (passageDocument.pdfUrl.trim() !== '') {
                this.videoCacheAcceptor(passageDocument.pdfUrl).catch(systemError)
            }
        }

        // The creation date of the audio file may not be the same as the creation date of the audio file
        if (doc.audioUrl !== undefined && passageDocument.audioUrl !== doc.audioUrl) {
            passageDocument.audioUrl = doc.audioUrl
            if (passageDocument.audioUrl.trim() !== '') {
                this.videoCacheAcceptor(passageDocument.audioUrl).catch(systemError)
            }
        }

        const envIndex = doc._id.startsWith(DbObjectIdPrefix.PROJECT_DOCUMENT) ? 0 : 2
        env[envIndex].documents = env[envIndex].documents.slice().sort(this.sortByRank)
    }

    acceptPassageVideo(passageVideo: PassageVideo, env: any[], doc: any) {
        const passage: Passage = env[2]

        // If this doc includes a viewedByUser field it is intended to
        // only update thie viewedBy attribute and no others
        if (doc.viewedByUser) {
            const viewedByUser = normalizeUsername(doc.viewedByUser)
            if (passageVideo.viewedBy.includes(viewedByUser)) return
            passageVideo.viewedBy.push(viewedByUser)
        }

        if (doc._id.endsWith('@uploaded')) {
            return
        }

        if (doc.title !== undefined && passageVideo.title !== doc.title) {
            passageVideo.title = doc.title
        }

        if (doc.isPatch !== undefined && passageVideo.isPatch !== doc.isPatch) {
            passageVideo.isPatch = doc.isPatch
        }

        if (doc.version !== undefined && passageVideo.version !== doc.version) {
            passageVideo.version = doc.version
        }

        if (doc.duration !== undefined && passageVideo.duration !== doc.duration) {
            passageVideo.duration = doc.duration

            // This next line should not be necessary.
            // It may however prevent a major crash if we forget to compute the duration.
            passageVideo.computedDuration = doc.duration
        }

        if (doc.status !== undefined && passageVideo.status !== doc.status) {
            if (typeof doc.status !== 'string') doc.status = ''

            passageVideo.status = doc.status
        }

        if (doc.url !== undefined && passageVideo.url !== doc.url) {
            passageVideo.url = doc.url
        }

        this.acceptCreationInfo(passageVideo, doc)

        if (doc.rank !== undefined && passageVideo.rank !== doc.rank) {
            passageVideo.rank = doc.rank
        }

        if (doc.ffmpegParametersUsed !== undefined) {
            const ffmpegParametersUsed = JSON.parse(doc.ffmpegParametersUsed)
            const {
                inputOptions,
                audioFilters,
                videoFilters,
                complexFilter,
                complexFilterOutputMapping,
                outputOptions
            } = ffmpegParametersUsed
            if (
                inputOptions !== undefined &&
                inputOptions.length !== undefined &&
                audioFilters !== undefined &&
                audioFilters.length !== undefined &&
                videoFilters !== undefined &&
                videoFilters.length !== undefined &&
                complexFilter !== undefined &&
                complexFilter.length !== undefined &&
                complexFilterOutputMapping !== undefined &&
                complexFilterOutputMapping.length !== undefined &&
                outputOptions !== undefined &&
                outputOptions.length !== undefined
            ) {
                const parameters = new FfmpegParameters()
                parameters.inputOptions = inputOptions
                parameters.audioFilters = audioFilters
                parameters.videoFilters = videoFilters
                parameters.complexFilter = complexFilter
                parameters.complexFilterOutputMapping = complexFilterOutputMapping
                parameters.outputOptions = outputOptions
                passageVideo.ffmpegParametersUsed = parameters
            }
        }

        if (doc.mimeType !== undefined && passageVideo.mimeType !== doc.mimeType) {
            passageVideo.mimeType = doc.mimeType
        }

        // If a segment has an end position of 0, assume it hasn't been set yet.
        // The reason we do this here and not when the segments are created is we
        // need access to the full list of segments in the video.
        passageVideo.segments.forEach((s) => {
            if (s.endPosition === 0) {
                s.setDefaultEndPosition(passageVideo)
            }
        })

        env[2].videos = env[2].videos.slice().sort(this.sortByRank)

        // Force any view looking at this passage or passageVideo to redraw.
        // This should be done list to make sure all other updates have been completed.
        passageVideo._rev += 1
        passage._rev += 1

        // Normally every passageVideo should have a url.
        // There was a bug at one point that created some without.
        // If there is a url, notify the cache so that it (potentially) be downloaded.
        // This call triggers an upload if the video has been created locally
        // but not yet uploaded.
        if (passageVideo.url) {
            this.videoCacheAcceptor(passageVideo.url).catch(systemError)
        }
    }

    acceptPassageSegment(passageSegment: PassageSegment, env: any[], doc: any) {
        const passage: Passage = env[2]
        const passageVideo: PassageVideo = env[3]

        let { position } = passageSegment
        if (doc.position !== undefined) {
            // Segments with identical positions mess up rendering,
            // bump position up until it is unique
            position = doc.position
            passageSegment.position = position
            while (passageVideo.segments.filter((pvs) => pvs.position === position).length > 1) {
                position += 0.001
                passageSegment.position = position
            }
        }

        // A default value for end position will be provided when all fields in the passage
        // video have been filled in, so we don't need to provide it here.
        if (doc.endPosition !== undefined) {
            passageSegment.endPosition = doc.endPosition
        }

        if (
            doc.videoPatchHistory !== undefined &&
            JSON.stringify(passageSegment.videoPatchHistory) !== JSON.stringify(doc.videoPatchHistory)
        ) {
            passageSegment.videoPatchHistory = doc.videoPatchHistory
        }

        if (doc.approved !== undefined && passageSegment.approved !== doc.approved) {
            if (doc.approved === true) {
                // Handle docs created before multi-state approval
                passageSegment.approved = PassageSegmentApproval.State1
            } else if (doc.approved === false) {
                passageSegment.approved = PassageSegmentApproval.State0
            } else {
                passageSegment.approved = doc.approved
            }
        }

        if (doc.approvalDate !== undefined && passageSegment.approvalDate !== doc.approvalDate) {
            passageSegment.approvalDate = doc.approvalDate
        }

        if (doc.approvedBy !== undefined && passageSegment.approvedBy !== doc.approvedBy) {
            passageSegment.approvedBy = doc.approvedBy
        }

        if (doc.references !== undefined) {
            // Convert old-style string references to RefRange[]
            let refRanges: RefRange[] = []
            if (doc.references === '' || doc.references[0] !== '[') {
                refRanges = RefRange.parseReferences(doc.references, 'en', getBookNames('en'))
            } else {
                const unserializedReferences = JSON.parse(doc.references)
                if (JSON.stringify(passageSegment.references) !== unserializedReferences) {
                    refRanges = unserializedReferences.map((ref: any) => new RefRange(ref.startRef, ref.endRef))
                }
            }
            passageSegment.references = refRanges
        }

        if (doc.labels !== undefined) {
            passageSegment.labels = doc.labels.map(
                (lb: any) => new PassageSegmentLabel(lb.x, lb.y, lb.xText, lb.yText, lb.text)
            )
        }

        if (doc.cc !== undefined && passageSegment.cc !== doc.cc) {
            passageSegment.cc = doc.cc
        }

        this.acceptCreationInfo(passageSegment, doc)

        if (doc.glosses !== undefined) {
            passageSegment.glosses = doc.glosses.map((psg: any) => new PassageSegmentGloss(psg.identity, psg.gloss))
        }

        if (doc.ignoreWhenPlayingVideo !== undefined) {
            passageSegment.ignoreWhenPlayingVideo = doc.ignoreWhenPlayingVideo
        }

        if (doc.isHidden !== undefined && passageSegment.isHidden !== doc.isHidden) {
            passageSegment.isHidden = doc.isHidden
        }

        passageSegment.rank = DBObject.numberToRank(position)

        passageVideo.segments = passageVideo.segments.slice().sort(this.sortByRank)

        passageSegment._rev += 1
        this.updateBaseVideoRev(passage, passageVideo)
        passage._rev += 1 // cause passage views to re-render
    }

    acceptPassageGloss(passageGloss: PassageGloss, env: any[], doc: any) {
        const passage: Passage = env[2]
        const passageVideo: PassageVideo = env[3]

        // glosses with identical positions mess up rendering,
        // bump position up until it is unique
        let { position } = doc
        passageGloss.position = position
        while (passageVideo.glosses.filter((pvs) => pvs.position === position).length > 1) {
            position += 0.1
            passageGloss.position = position
        }

        if (doc.text !== undefined && passageGloss.text !== doc.text) {
            passageGloss.text = doc.text
        }

        this.acceptCreationInfo(passageGloss, doc)

        passageGloss.rank = DBObject.numberToRank(position)

        passageVideo.glosses = passageVideo.glosses.slice().sort(this.sortByRank)

        const i = passageVideo.glosses.findIndex((pg) => pg.position === passageGloss.position)
        if (i < 0) {
            systemError('Cant find PassageGloss we just inserted')
            return
        }

        passageGloss._rev += 1
        this.updateBaseVideoRev(passage, passageVideo)
        passage._rev += 1 // cause passage views to re-render
    }

    acceptPassageVideoReference(passageVideoReference: ReferenceMarker, env: any[], doc: any) {
        const passage: Passage = env[2]
        const passageVideo: PassageVideo = env[3]

        this.acceptCreationInfo(passageVideoReference, doc)

        if (doc.position !== undefined && passageVideoReference.position !== doc.position) {
            passageVideoReference.position = doc.position
        }

        if (doc.references !== undefined && JSON.stringify(passageVideoReference.references) !== doc.references) {
            const unserializedReferences = JSON.parse(doc.references)
            const refRanges = unserializedReferences.map((ref: any) => new RefRange(ref.startRef, ref.endRef))
            passageVideoReference.references = refRanges
        }

        if (doc.rank !== undefined && passageVideoReference.rank !== doc.rank) {
            passageVideoReference.rank = doc.rank
        }

        passageVideo.references = passageVideo.references.slice().sort(this.sortByRank)

        passageVideo._rev += 1
        passage._rev += 1
    }

    acceptPassageHighlight(passageHighlight: PassageHighlight, env: any[], doc: any) {
        // let passage: Passage = env[2]
        const passageVideo: PassageVideo = env[3]

        if (doc.color !== undefined && passageHighlight.color !== doc.color) {
            passageHighlight.color = doc.color
        }

        if (doc.firstId !== undefined && passageHighlight.firstId !== doc.firstId) {
            passageHighlight.firstId = doc.firstId
        }

        if (doc.lastId !== undefined && passageHighlight.lastId !== doc.lastId) {
            passageHighlight.lastId = doc.lastId
        }

        if (doc.resourceName !== undefined && passageHighlight.resourceName !== doc.resourceName) {
            passageHighlight.resourceName = doc.resourceName
        }

        passageHighlight.rank = doc._id

        this.acceptCreationInfo(passageHighlight, doc)

        passageVideo.highlights = passageVideo.highlights.slice().sort(this.sortByRank)
    }

    acceptPassageNote(passageNote: PassageNote, env: any[], doc: any) {
        const passage: Passage = env[2]
        const passageVideo: PassageVideo = env[3]

        if (doc.type !== undefined && passageNote.type !== doc.type) {
            passageNote.type = doc.type
        }

        if (doc.description !== undefined && passageNote.description !== doc.description) {
            passageNote.description = doc.description
        }

        if (doc.canResolve !== undefined && passageNote.canResolve !== doc.canResolve) {
            passageNote.canResolve = doc.canResolve
        }

        if (doc.position !== undefined && passageNote.position !== doc.position) {
            passageNote.position = doc.position

            // If the endPosition is 0 we assume that the defult values for start/edn
            // have not been set yet and set them
            if (passageNote.endPosition === 0) {
                passageNote.setDefaultStartPosition()
                passageNote.setDefaultEndPosition(passageVideo.duration)
            }
        }

        if (doc.startPosition !== undefined && passageNote.startPosition !== doc.startPosition) {
            passageNote.startPosition = doc.startPosition
        }

        if (doc.endPosition !== undefined && passageNote.endPosition !== doc.endPosition) {
            passageNote.endPosition = doc.endPosition
        }

        this.acceptCreationInfo(passageNote, doc)

        if (doc.rank !== undefined && passageNote.rank !== doc.rank) {
            passageNote.rank = doc.rank
        }

        passageVideo.notes = passageVideo.notes.slice().sort(this.sortByRank)

        passageNote._rev += 1
        this.updateBaseVideoRev(passage, passageVideo)
        passage._rev += 1 // cause passage views to re-render
    }

    // If this video is a patch update the rev of the patch video.
    // Otherwise update the rev of this video.
    updateBaseVideoRev(passage: Passage, passageVideo: PassageVideo) {
        // Don't complain about missing base videos here because
        // they seem to be caused by the base video being deleted
        const baseVideo = passageVideo.baseVideo(passage, true)

        if (baseVideo) {
            // This note occurs on a patch, reset times on the base video
            baseVideo.resetSegmentTimes()
            baseVideo._rev += 1
        } else {
            passageVideo.resetSegmentTimes()
            passageVideo._rev += 1
        }
    }

    acceptPassageSegmentDocument(passageSegmentDocument: PassageSegmentDocument, env: any[], doc: any) {
        this.acceptCreationInfo(passageSegmentDocument, doc)
        if (doc.text !== undefined && passageSegmentDocument.text !== doc.text) {
            passageSegmentDocument.textHistory = getUpdatedTextHistory(passageSegmentDocument.textHistory, doc)
            passageSegmentDocument.text = doc.text
        }

        const passageSegment: PassageSegment = env[4]
        passageSegment.documents = passageSegment.documents.slice().sort(this.sortByRank)
    }

    acceptAudioClip(audioClip: AudioClip, env: any[], doc: any) {
        this.acceptCreationInfo(audioClip, doc)

        if (doc.url !== undefined && audioClip.url !== doc.url) {
            audioClip.url = doc.url
            if (audioClip.url.trim() !== '') {
                this.videoCacheAcceptor(audioClip.url).catch(systemError)
            }
        }

        if (doc.duration !== undefined && audioClip.duration !== doc.duration) {
            audioClip.duration = doc.duration
        }

        const segment: PassageSegment = env[4]
        segment.audioClips = segment.audioClips.slice().sort(this.sortByRank)
    }

    acceptPassageSegmentTranscription(transcription: PassageSegmentTranscription, env: any[], doc: any) {
        this.acceptCreationInfo(transcription, doc)
        if (doc.text !== undefined && doc.text !== transcription.text) {
            transcription.textHistory = getUpdatedTextHistory(transcription.textHistory, doc)
            transcription.text = doc.text
        }

        const passageSegment: PassageSegment = env[4]
        passageSegment.transcriptions = passageSegment.transcriptions.slice().sort(this.sortByRank)
    }

    acceptPassageNoteItem(passageNoteItem: PassageNoteItem, env: any[], doc: any) {
        const passage: Passage = env[2]
        const passageVideo: PassageVideo = env[3]
        const passageNote: PassageNote = env[4]

        // If this doc includes a viewedByUser field it is intended to
        // only update thie viewedBy attribute and no others
        if (doc.viewedByUser) {
            const viewedByUser = normalizeUsername(doc.viewedByUser)
            if (passageNoteItem.viewedBy.includes(viewedByUser)) return
            passageNoteItem.viewedBy.push(viewedByUser)
        }

        if (doc.position !== undefined && passageNoteItem.position !== doc.position) {
            passageNote.position = doc.position
            passageNoteItem.position = doc.position

            // If the endPosition is 0 we assume that the defult values for start/edn
            // have not been set yet and set them
            if (passageNote.endPosition === 0) {
                passageNote.setDefaultStartPosition()
                passageNote.setDefaultEndPosition(passageVideo.duration)
            }
        }

        if (doc.duration !== undefined && passageNoteItem.duration !== doc.duration) {
            passageNoteItem.duration = doc.duration
        }

        if (doc.url !== undefined) {
            const { url } = doc
            if (passageNoteItem.url !== url) {
                passageNoteItem.url = url
            }
        }

        if (doc.fileType !== undefined && passageNoteItem.fileType !== doc.fileType) {
            passageNoteItem.fileType = doc.fileType
        }

        if (doc.text !== undefined && passageNoteItem.text !== doc.text) {
            passageNoteItem.text = doc.text
        }

        if (doc.resolved !== undefined && passageNoteItem.resolved !== doc.resolved) {
            passageNoteItem.resolved = doc.resolved
        }

        if (doc.unresolved !== undefined && passageNoteItem.unresolved !== doc.unresolved) {
            passageNoteItem.unresolved = doc.unresolved
        }

        this.acceptCreationInfo(passageNoteItem, doc)

        if (doc.rank !== undefined && passageNoteItem.rank !== doc.rank) {
            passageNoteItem.rank = doc.rank
        }

        if (doc.consultantOnly !== undefined && passageNoteItem.consultantOnly !== doc.consultantOnly) {
            passageNoteItem.consultantOnly = doc.consultantOnly
        }

        passageNote.items = passageNote.items.slice().sort(this.sortByRank)

        passageNoteItem._rev += 1
        this.updateBaseVideoRev(passage, passageVideo)
        passageNote._rev += 1 // cause passageNote and passage views to render
        passage._rev += 1

        if (passageNoteItem.url) {
            // This call triggers an uploaded if the video has been created locally
            // but not yet uploaded.
            this.videoCacheAcceptor(passageNoteItem.url).catch(systemError)
        }
    }

    cleanUp() {
        this.cleanUpOrphanVideos()

        // The acceptor will automatically create a no name Portion to hold passages.
        // Sometimes this might happen due bugs generating bogus passages that are later removed.
        // If the portion has no name and no passages, remove it.
        this.project.portions = this.project.portions.filter((portion) => portion.name || portion.passages.length)
    }

    // The acceptor will automatically create a PassageVideo to hold a segment or a note or a gloss.
    // If some bug causes us to never get a url for that PassageVideo, remove the video.
    cleanUpOrphanVideos() {
        for (const portion of this.project.portions) {
            for (const passage of portion.passages) {
                if (passage.videos.some((video) => !video.url)) {
                    passage.videos = passage.videos.filter((video) => video.url)
                }
            }
        }
    }

    sortByRank = (a: any, b: any) => (a.rank < b.rank ? -1 : a.rank === b.rank ? 0 : 1)
}
