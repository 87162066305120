import { FC } from 'react'

import { ColorPicker as CustomColorPicker, useColor } from 'react-color-palette'
import 'react-color-palette/dist/css/rcp.css'

interface IColorPicker {
    color: string
    setColor?: (hexColor: string) => void
}

const ColorPicker: FC<IColorPicker> = ({ color, setColor }) => {
    const [currentColor, setCurrentColor] = useColor(color)

    return (
        <CustomColorPicker
            height={200}
            color={currentColor}
            onChange={(newColor) => {
                setCurrentColor(newColor)
                setColor?.(newColor.hex)
            }}
            hideInput={['rgb', 'hsv', 'hex']}
        />
    )
}

export default ColorPicker
