import { FC, createContext, useCallback, useContext, useEffect, useState } from 'react'

import { useAppRoot } from './RootContext'
import { fetchMarbleImageIndex, MarbleImagesIndex } from '../../resources/MARBLEImages'

interface MarbleImagesContextValue {
    marbleImagesIndex?: MarbleImagesIndex
    getNewPath: (oldPath: string) => string
}

const MarbleImagesContext = createContext<MarbleImagesContextValue | undefined>(undefined)

const directoryMap = new Map<string, string>([
    ['NBG/Other biblical', 'NBG/OTHER BIBLICAL'],
    ['KNOWLES/600TIF', 'KNOWLES'],
    ['NBG/Israel', 'NBG/ISRAEL'],
    ['NBG/Other biblical', 'NBG/OTHER BIBLICAL'],
    ['Bolen', 'BOLEN'],
    ['Cook', 'COOK'],
    ['HARVEY-SMEMO', 'UBS'],
    ['Satellite Bible Atlas', 'SATELLITE BIBLE ATLAS']
])

const getNewImagePath = (oldPath: string) => {
    if (['FAUNA', 'FLORA'].some((path) => oldPath.startsWith(path))) {
        return 'WEB'
    }

    if (oldPath.startsWith('REALIA')) {
        return 'UBS'
    }

    return directoryMap.get(oldPath) ?? oldPath
}

export const MarbleImagesProvider: FC = ({ children }) => {
    const { rt } = useAppRoot()
    const { project } = rt ?? {}
    const [marbleImagesIndex, setMarbleImagesIndex] = useState<MarbleImagesIndex>()

    useEffect(() => {
        const fetchImagesIndex = async () => {
            try {
                const index = await fetchMarbleImageIndex()
                setMarbleImagesIndex(index)
            } catch (error) {
                console.error('Error fetching Marble Images index. Some AVTT features may not work properly.', error)
            }
        }
        fetchImagesIndex()
    }, [project])

    const getNewPath = useCallback(
        (path: string) => {
            if (!marbleImagesIndex) {
                return path
            }

            const parts = path.split('/')
            const fileName = parts.length < 2 ? path : parts[parts.length - 1]
            const newFileName = marbleImagesIndex.legacyImages[fileName] ?? fileName
            return parts.length < 2
                ? newFileName
                : [getNewImagePath(parts.slice(0, -1).join('/')), newFileName].join('/')
        },
        [marbleImagesIndex]
    )

    return (
        <MarbleImagesContext.Provider
            // eslint-disable-next-line react/jsx-no-constructed-context-values
            value={{ marbleImagesIndex, getNewPath }}
        >
            {children}
        </MarbleImagesContext.Provider>
    )
}

export const useMarbleImages = () => {
    const context = useContext(MarbleImagesContext)
    if (!context) {
        throw new Error('useMarbleImages must be used within a MarbleImagesProvider')
    }
    return context
}
