// Fetch images info and images from MARBLE

import { ImageMetadata } from './ImageMetadata'
import { RefRange } from './RefRange'
import { avttVersion, MARBLE_IMAGES_BASE_PATH } from '../components/app/slttAvtt'
import { fetchAndWaitForBody } from '../components/utils/Helpers'

// corresponds to path in S3 Marble images bucket
export enum ImageResolution {
    THUMBNAIL = 'thumbnail',
    MEDIUM = 'Medium'
}

class MARBLEImageDescription {
    public languageCode = ''

    public title = ''

    public description = ''
}

class MARBLEImageInfo {
    public id = '' // e.g. "LB00071"

    public languageCode?: string // e.g. "en", optional. This is used when there are labels in an image

    public path = '' // e.g. "KNOWLES"

    public fileName = '' // e.g. "LB00071B.jpg"

    public copyright = '' // e.g. "Louise Bass © BFBS 1994"

    public definitions: MARBLEImageDescription[] = []

    public thumbnail = '' // URL

    public references: string[] = [] // e.g. ["00100102000022-00100102100052", "00100102200040"]

    // references format is bbbcccvvv
    // last 5 digits are a word number in original text, ignore, they should go away
}

export interface MarbleImagesIndex {
    generatedAt: number
    legacyImages: Record<string, string>
}

export class MARBLEImages {
    // Fetch the information for all MARBLE images in a specific book/chapter

    private static imagesByBbbccc: { [project: string]: ImageMetadata[] } = {}

    static async fetchInfo(bbbccc: string, params?: RequestInit): Promise<ImageMetadata[]> {
        const { imagesByBbbccc } = MARBLEImages

        let images = imagesByBbbccc[bbbccc]
        if (!images) {
            try {
                images = await MARBLEImages.getMARBLEImages(bbbccc, params)
                imagesByBbbccc[bbbccc] = images
            } catch (error) {
                // swallow error since the metadata file may not exist for some chapters
                console.error(`Image metadata not fetched for ${bbbccc}`, error)
            }
        }

        return images
    }

    static async getMARBLEImagesResponseWithBody(
        bbbccc: string, // e.g. 001002 (Gen 2)
        params?: RequestInit
    ) {
        const url = `${MARBLE_IMAGES_BASE_PATH}/bychapter/${bbbccc}.json`
        const { response, body } = await fetchAndWaitForBody(url, params)

        if (!response.ok) {
            return { response, body: [] }
        }

        return { response, body }
    }

    static async getMARBLEImages(
        bbbccc: string, // e.g. 001002 (Gen 2)
        params?: RequestInit
    ): Promise<ImageMetadata[]> {
        const { body } = await this.getMARBLEImagesResponseWithBody(bbbccc, params)

        return (body as MARBLEImageInfo[]).map((imageInfo) => {
            const { id, languageCode, definitions, path, fileName, copyright, references } = imageInfo
            const refRanges = references.map((reference) => {
                const parts = reference.split('-')
                const start = parts[0].substring(0, 9)
                const end = parts[1] ? parts[1].substring(0, 9) : start
                return new RefRange(start, end)
            })
            const image = new ImageMetadata()
            image.id = id

            if (languageCode) {
                image.languageCode = languageCode
            }

            image.definitions = definitions
            image.path = path
            image.fileName = fileName
            image.copyright = copyright
            image.references = refRanges

            image.setSortKey()

            return image
        })
    }
}

export const fetchMarbleImageIndex = async (): Promise<MarbleImagesIndex> => {
    const response = await fetch(`${MARBLE_IMAGES_BASE_PATH}/index.json?avttVersion=${avttVersion}`)
    if (!response.ok) {
        throw Error(`${response.url}: ${response.statusText}`)
    }
    const index = (await response.json()) as MarbleImagesIndex
    return index
}
