import _ from 'underscore'

import { AudioClip } from './AudioClip'
import { BiblicalTermMarker } from './BiblicalTermMarker'
import { Passage } from './Passage'
import { PassageDocument } from './PassageDocument'
import { PassageGloss } from './PassageGloss'
import { PassageHighlight } from './PassageHighlight'
import { PassageNote } from './PassageNote'
import { PassageNoteItem } from './PassageNoteItem'
import { PassageSegment } from './PassageSegment'
import { PassageSegmentDocument } from './PassageSegmentDocument'
import { PassageSegmentTranscription } from './PassageSegmentTranscription'
import { PassageVideo } from './PassageVideo'
import { Portion } from './Portion'
import { Project } from './Project'
import { ProjectTerm } from './ProjectTerm'
import { ReferenceMarker } from './ReferenceMarker'
import { TargetGloss } from './TargetGloss'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const log = require('debug')('avtt:PassageCopier')

const copyTargetGloss = async (term: ProjectTerm, gloss: TargetGloss) => {
    // We must search by text instead of _id, because the glosses might be in another
    // project and thus have a different _id.
    const existingGloss = term.glosses.find((g) => g.text === gloss.text)
    return existingGloss ?? term.addGloss(gloss.text)
}

const addNewTerm = async (project: Project, oldTerm: ProjectTerm) => {
    const brandNewTerm = project.createProjectTermFromExisting(oldTerm)
    const savedNewTerm = await project.addProjectTerm(brandNewTerm)
    if (!savedNewTerm) {
        return
    }

    // Don't copy target glosses, because we only want to copy the ones that are actually used
    for (const rendering of oldTerm.renderings) {
        const newRendering = savedNewTerm.createRenderingFromExisting(rendering)
        await savedNewTerm.addRendering(newRendering)
    }
    return savedNewTerm
}

const copyTerm = async (project: Project, term: ProjectTerm) => {
    const existingTerm = project.terms.find((t) => t.lexicalLink === term.lexicalLink)
    if (existingTerm && term.isKeyTerm) {
        await existingTerm.setIsKeyTerm(true)
    }
    return existingTerm ?? addNewTerm(project, term)
}

const getProjectTermAndTargetGloss = (project: Project, glossId: string) => {
    const oldTerm = project.terms.find((term) => term.glosses.some((gloss) => glossId === gloss._id))
    if (!oldTerm) {
        return {}
    }

    const oldGloss = oldTerm.glosses.find((gloss) => glossId === gloss._id)
    return { term: oldTerm, gloss: oldGloss }
}

// Copy a passage and its components and store them in the database.
export class PassageCopyVisitor {
    private includeResources: boolean

    private newProject: Project

    private oldProject: Project

    private oldPassage: Passage

    private newPassage?: Passage

    private newPortion: Portion

    private newSegment?: PassageSegment

    private newNote?: PassageNote

    private newVideo?: PassageVideo

    private latestDraftOnly: boolean

    private oldVideoToNewMap = new Map<string, PassageVideo>()

    constructor({
        newProject,
        oldProject,
        newPortion,
        oldPassage,
        includeResources,
        latestDraftOnly
    }: {
        newProject: Project
        oldProject: Project
        newPortion: Portion
        oldPassage: Passage
        includeResources: boolean
        latestDraftOnly: boolean
    }) {
        this.newProject = newProject
        this.oldProject = oldProject
        this.newPortion = newPortion
        this.oldPassage = oldPassage
        this.includeResources = includeResources
        this.latestDraftOnly = latestDraftOnly
    }

    async visit() {
        const { oldProject, newPortion, includeResources, oldPassage, latestDraftOnly } = this
        if (oldPassage.removed) {
            return
        }

        const newPassage = newPortion.createPassageFromExisting(oldPassage)
        newPassage.copiedFromId = `${oldProject.name}/${oldPassage._id}`
        this.newPassage = await newPortion.addPassageFromExisting(newPassage)

        await Promise.all([
            this.newPassage.setReferences(oldPassage.references.map((ref) => ref.copy())),
            this.copyVideos(oldPassage, latestDraftOnly)
        ])

        if (includeResources) {
            await Promise.all([oldPassage.documents.map((doc) => this.visitPassageDocument(doc))].flat())
        }
    }

    private async copyVideos(passage: Passage, latestDraftOnly: boolean) {
        const { newPassage } = this
        if (!newPassage || passage.removed) {
            return
        }

        let oldVideos
        let oldPatches
        let oldNonPatches
        if (latestDraftOnly) {
            oldNonPatches = passage.videosNotDeleted.filter((video) => !video.isPatch).slice(-1) ?? []
            if (!oldNonPatches.length) {
                return
            }

            oldPatches = [...oldNonPatches[0].patchVideos(passage)].map((v) => v.patchVideo)
            oldVideos = oldNonPatches.concat(oldPatches)
        } else {
            oldVideos = passage.videos
            ;[oldPatches, oldNonPatches] = _.partition(oldVideos, (v) => v.isPatch)
        }

        this.oldVideoToNewMap = new Map()

        // copy patches before anything else, because other segments reference them
        for (const video of oldPatches) {
            const newVideo = await this.visitPassageVideoAndSegments(video)
            if (newVideo) {
                this.oldVideoToNewMap.set(video._id, newVideo)
            }
        }

        // copy all other videos
        for (const video of oldNonPatches) {
            const newVideo = await this.visitPassageVideoAndSegments(video)
            if (newVideo) {
                this.oldVideoToNewMap.set(video._id, newVideo)
            }
        }

        // Now that all segments have been copied, we can copy things that depend on them existing
        for (const oldVideo of oldVideos) {
            const newVideo = this.oldVideoToNewMap.get(oldVideo._id)
            if (!newVideo) {
                continue
            }

            this.newVideo = newVideo

            const baseVideo = newVideo.baseVideo(newPassage) || newVideo
            if (baseVideo.isPatch) {
                log('orphaned patch?', baseVideo._id)
                continue
            }

            // Copying certain items depends on certain fields being set
            baseVideo.setSegmentTimes(newPassage)

            for (const marker of oldVideo.biblicalTermMarkers) {
                await this.visitBiblicalTermMarker(marker)
            }

            for (const note of oldVideo.notes) {
                await this.visitPassageNote(note)
            }

            for (const gloss of oldVideo.glosses) {
                await this.visitPassageGloss(gloss)
            }

            for (const highlight of oldVideo.highlights) {
                await this.visitPassageHighlight(highlight)
            }

            for (const reference of oldVideo.references) {
                await this.visitPassageVideoReference(reference)
            }
        }
    }

    private async visitPassageVideoAndSegments(video: PassageVideo) {
        const { newPassage } = this
        if (!newPassage) {
            return null
        }

        // We want to add removed passage videos. Unlike other objects, removed passage
        // videos stay around in the model.
        const newVideo = newPassage.createVideoFromExisting(video)
        newVideo.removed = video.removed
        const _video = await newPassage.addVideo(newVideo)
        this.newVideo = _video

        for (const oldSegment of video.segments) {
            await this.visitPassageSegment(oldSegment)
        }

        return _video
    }

    private async copyMarker(marker: BiblicalTermMarker, gloss: TargetGloss) {
        const { newVideo } = this
        if (!newVideo) {
            return
        }

        const newMarker = newVideo.createMarkerFromExisting(marker)
        if (newMarker instanceof BiblicalTermMarker) {
            newMarker.targetGlossId = gloss._id
        }
        if (newMarker) {
            await newVideo.addMarkerFromExisting(newMarker)
        }
    }

    private async copyTargetGlossAndProjectTerm(marker: BiblicalTermMarker) {
        const { oldProject, newProject } = this
        const { term, gloss } = getProjectTermAndTargetGloss(oldProject, marker.targetGlossId)
        if (!term || !gloss) {
            return
        }

        const copiedTerm = await copyTerm(newProject, term)
        if (!copiedTerm) {
            return
        }

        return copyTargetGloss(copiedTerm, gloss)
    }

    private async visitBiblicalTermMarker(marker: BiblicalTermMarker) {
        const { newVideo } = this
        if (!newVideo || marker.removed) {
            return null
        }

        const newGloss = await this.copyTargetGlossAndProjectTerm(marker)
        if (!newGloss) {
            return
        }

        await this.copyMarker(marker, newGloss)
    }

    private async visitPassageDocument(doc: PassageDocument) {
        const { newPassage } = this
        if (!newPassage || doc.removed) {
            return
        }
        const copy = newPassage.createDocumentFromExisting(doc)
        if (copy) {
            await this.copyObjectAndTextHistory(newPassage, copy)
        }
    }

    private async visitPassageSegment(segment: PassageSegment) {
        const { newVideo, oldVideoToNewMap } = this
        if (!newVideo || segment.removed) {
            return
        }

        const copy = newVideo.createSegmentFromExisting(segment)
        if (!copy) {
            return
        }

        copy.videoPatchHistory = segment.videoPatchHistory
            .map((entry) => {
                return oldVideoToNewMap.get(entry)?._id ?? ''
            })
            .filter((s) => s.trim() !== '')

        const _segment = await newVideo.addSegmentFromExisting(copy)
        this.newSegment = _segment
        for (const oldDocument of segment.documents) {
            await this.visitPassageSegmentDocument(oldDocument)
        }

        for (const oldClip of segment.audioClips) {
            await this.visitAudioClip(oldClip)
        }

        for (const oldTranscription of segment.transcriptions) {
            await this.visitPassageSegmentTranscription(oldTranscription)
        }
    }

    private async visitPassageSegmentDocument(segmentDocument: PassageSegmentDocument) {
        const { newSegment } = this
        if (!newSegment || segmentDocument.removed) {
            return
        }
        const copy = newSegment.createPassageSegmentDocumentFromExisting(segmentDocument)
        if (copy) {
            await this.copyObjectAndTextHistory(newSegment, copy)
        }
    }

    private async visitAudioClip(audioClip: AudioClip) {
        const { newSegment } = this
        if (!newSegment || audioClip.removed) {
            return
        }
        const newClip = newSegment.createAudioClipFromExisting(audioClip, '')
        if (newClip) {
            await newSegment.addAudioClip(newClip)
        }
    }

    private async visitPassageSegmentTranscription(transcription: PassageSegmentTranscription) {
        const { newSegment } = this
        if (!newSegment || transcription.removed) {
            return
        }
        const copy = newSegment.createTranscriptionFromExisting(transcription)
        if (copy) {
            await this.copyObjectAndTextHistory(newSegment, copy)
        }
    }

    private async saveResource(
        source: PassageSegment | Passage,
        copy: PassageSegmentTranscription | PassageSegmentDocument | PassageDocument
    ): Promise<PassageSegmentTranscription | PassageSegmentDocument | PassageDocument> {
        if (source instanceof PassageSegment) {
            if (copy instanceof PassageSegmentTranscription) {
                return source.addTranscription(copy, true, true)
            }
            return source.addPassageSegmentDocument(copy, true, true)
        }
        return source.addDocument(copy as PassageDocument, true, true)
    }

    private async copyObjectAndTextHistory(
        source: PassageSegment | Passage,
        copy: PassageSegmentTranscription | PassageSegmentDocument | PassageDocument
    ) {
        let savedResource: PassageSegmentTranscription | PassageSegmentDocument | PassageDocument | undefined

        for (const [i, { text, modBy, modDate }] of copy.textHistory.entries()) {
            if (i === 0) {
                copy.modDate = modDate
                copy.modBy = modBy
                copy.text = text
                copy.textHistory = []
                savedResource = await this.saveResource(source, copy)
            } else if (savedResource instanceof PassageDocument) {
                await savedResource?.setText(text, this.newProject.name, modBy, modDate)
            } else {
                await savedResource?.setText(text, modBy, modDate)
            }
        }
    }

    private async visitPassageNote(note: PassageNote) {
        const { newVideo } = this
        if (!newVideo || note.removed) {
            return
        }

        const copy = newVideo.createNoteFromExisting(note)
        if (!copy) {
            return
        }
        const updatedVideo = await newVideo.addNote(copy)
        const _note = updatedVideo.notes.find((n) => n._id === copy?._id)
        if (!_note) {
            throw new Error('Could not find new note we just created')
        }
        this.newNote = _note
        for (const item of note.items) {
            await this.visitPassageNoteItem(item)
        }
    }

    private async visitPassageNoteItem(item: PassageNoteItem) {
        const { newNote, newPassage } = this
        if (!newNote || !newPassage || item.removed) {
            return
        }

        const copy = newNote.createItemFromExisting(item)
        if (copy) {
            await newNote.addItem(copy, newPassage)
        }
    }

    private async visitPassageGloss(gloss: PassageGloss) {
        const { newVideo } = this
        if (!newVideo || gloss.removed) {
            return
        }

        const pg = newVideo.createGlossFromExisting(gloss)
        if (pg) {
            await newVideo.addGlossFromExisting(pg)
        }
    }

    private async visitPassageHighlight(highlight: PassageHighlight) {
        const { newVideo } = this
        if (!newVideo || highlight.removed) {
            return
        }

        const copy = newVideo.createHighlightFromExisting(highlight)
        if (copy) {
            await newVideo.addHighlightFromExisting(copy)
        }
    }

    private async visitPassageVideoReference(reference: ReferenceMarker) {
        const { newVideo } = this
        if (!newVideo || reference.removed) {
            return
        }

        const copy = newVideo.createMarkerFromExisting(reference)
        if (copy) {
            await newVideo.addMarkerFromExisting(copy)
        }
    }
}
