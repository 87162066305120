import { useTranslation } from 'react-i18next'

import { SlttHelp, enable, enableImg } from './Buttons'
import { helpDocUrl } from '../app/slttAvtt'

import './Icons.css'

interface IconProps {
    className: string
    dir?: 'ltr' | 'rtl'
    tooltip?: string
    style?: any
    enabled?: boolean
}

export type PassageSegmentsIconVariant = 'create' | 'delete' | 'disabled'

interface PassageSegmentsIconProps {
    className: string
    variant: PassageSegmentsIconVariant
    tooltip?: string
    style: any
}

export const PassageSegmentsIcon = ({ tooltip, style, className, variant }: PassageSegmentsIconProps) => {
    const getVerticalColor = () => {
        if (variant === 'create') {
            return 'lightblue'
        }
        if (variant === 'delete') {
            return 'white'
        }
        return 'lightgrey'
    }
    const verticalColor = getVerticalColor()
    const horizontalColor = variant === 'create' || variant === 'delete' ? '#337ab7' : 'grey'

    return (
        <svg
            style={style}
            className={`${className} sl-create-passage-segment-svg`}
            viewBox="0 0 24 25"
            xmlns="http://www.w3.org/2000/svg"
        >
            <title>{tooltip}</title>
            <g>
                <line x1="0" y1="13" x2="8" y2="13" stroke={horizontalColor} strokeWidth="2" />
                <line x1="16" y1="13" x2="24" y2="13" stroke={horizontalColor} strokeWidth="2" />
                <line x1="12" y1="0" x2="12" y2="25" stroke={verticalColor} strokeWidth="4" />
            </g>
        </svg>
    )
}

interface PassageVideoReferenceIconProps {
    className: string
    onClick: () => void
    tooltip: string
    enabled: boolean
    iconClassName: string
}

export const PassageVideoReferenceIcon = ({
    className,
    onClick,
    tooltip,
    enabled,
    iconClassName
}: PassageVideoReferenceIconProps) => {
    const renderIcon = () => {
        const lineColor = enabled ? 'violet' : 'lightgrey'
        const cursor = enabled ? 'pointer' : 'default'
        return (
            <span className={className} data-toggle="tooltip" onClick={() => enabled && onClick()}>
                <svg
                    style={{ cursor, verticalAlign: 'middle' }}
                    className={iconClassName}
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                >
                    <g>
                        <line x1="4" y1="4" x2="12" y2="20" strokeWidth="5" stroke={lineColor} />
                        <line x1="12" y1="20" x2="20" y2="4" strokeWidth="5" stroke={lineColor} />
                    </g>
                </svg>
            </span>
        )
    }

    if (!tooltip) {
        return renderIcon()
    }

    return (
        <SlttHelp id="verses-add" tooltip={tooltip} place="bottom">
            {renderIcon()}
        </SlttHelp>
    )
}

interface PassageGlossIconProps {
    tooltip: string
    onClick: () => void
    enabled: boolean
    style: any
    className: string
}

export const PassageGlossIcon = ({ tooltip, onClick, enabled, style, className }: PassageGlossIconProps) => {
    let verticalColor
    let horizontalColor
    if (enabled) {
        verticalColor = 'lightblue'
        horizontalColor = 'black'
    } else {
        verticalColor = 'lightgrey'
        horizontalColor = 'grey'
    }

    return (
        <SlttHelp id="glosses" tooltip={tooltip} place="bottom">
            <span className={className} onClick={() => enabled && onClick()}>
                <svg
                    style={style}
                    className="sl-create-passage-gloss-svg"
                    width="24"
                    height="25"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <g>
                        <line x1="0" y1="13" x2="8" y2="13" stroke={horizontalColor} strokeWidth="2" />
                        <line x1="16" y1="13" x2="24" y2="13" stroke={horizontalColor} strokeWidth="2" />
                        <line x1="12" y1="0" x2="12" y2="4" stroke={verticalColor} strokeWidth="4" />
                        <line x1="12" y1="9" x2="12" y2="16" stroke={verticalColor} strokeWidth="4" />
                        <line x1="12" y1="21" x2="12" y2="25" stroke={verticalColor} strokeWidth="4" />
                    </g>
                </svg>
            </span>
        </SlttHelp>
    )
}

export const HeadsetIcon = ({ className, tooltip, enabled = true }: IconProps) => {
    return (
        <span className={enableImg(className, enabled)} data-toggle="tooltip" title={tooltip}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 490.982 490.982">
                <path
                    d="M445.474,148.461h-8.201C426.92,65.167,342.339,0,239.876,0C137.283,0,52.614,65.327,42.437,148.77
	c-23.365,1.606-41.946,20.943-41.946,44.707v118.849c0,24.825,20.191,45.016,45.001,45.016h51.553
	c24.81,0,45.001-20.191,44.986-45.016V193.477c0-24.825-20.191-45.016-45.001-45.016H73.62
	C84.183,82.148,154.636,30.686,239.876,30.686c85.269,0,155.71,51.462,166.272,117.775h-12.196
	c-24.825,0-45.017,20.206-45.017,45.016v118.849c0,24.825,20.207,45.016,45.017,45.016h10.224
	c-2.465,20.74-9.822,37.481-22.483,49.497c-27.142,25.747-69.612,23.736-70.871,23.767h-16.371
	c-0.254-24.61-20.303-44.556-44.97-44.556h-35.703c-24.81,0-45.002,20.207-45.002,45.017v14.913
	c0,24.81,20.192,45.001,45.002,45.001h35.703c19.427,0,35.862-12.434,42.158-29.701l18.294-0.019
	c0.322,0.031,1.857,0.108,4.311,0.108c13.762,0,57.015-2.516,88.346-32.083c19.019-17.901,29.922-42.092,32.667-71.945h10.217
	c24.81,0,45.002-20.191,45.017-45.016V193.477C490.491,168.652,470.284,148.461,445.474,148.461z"
                />
            </svg>
        </span>
    )
}

export const ImageReferencesIcon = ({ className, tooltip }: IconProps) => {
    return (
        <SlttHelp id="images" tooltip={tooltip} place="top">
            <span className={`${className} sl-fa-button far fa-image`} />
        </SlttHelp>
    )
}

export const PassageNotesIcon = ({ className, tooltip }: IconProps) => (
    <SlttHelp id="passage-notes" tooltip={tooltip} place="top">
        <span className={`${className} sl-fa-button fas fa-comment`} />
    </SlttHelp>
)

export const WarningIcon = ({ className, tooltip }: IconProps) => {
    return (
        <span
            className={`${className} sl-fa-button fas fa-fw fa-exclamation-triangle`}
            data-toggle="tooltip"
            title={tooltip}
        />
    )
}

export const SolidCheckCircle = ({ className, tooltip }: IconProps) => {
    return (
        <span className={`${className} sl-fa-button fas fa-fw fa-check-circle`} data-toggle="tooltip" title={tooltip} />
    )
}

export const TimesIcon = ({ className, tooltip }: IconProps) => {
    return <span className={`${className} sl-fa-button fas fa-fw fa-times`} data-toggle="tooltip" title={tooltip} />
}

export const LoadingIcon = ({ className }: Omit<IconProps, 'tooltip'>) => {
    return <span className={`${className} sl-fa-button fas fa-fw fa-spinner fa-spin`} />
}

export const ReferencesIcon = ({ className, tooltip }: IconProps) => {
    return <span className={`${className} sl-fa-button fas fa-fw fa-book`} data-toggle="tooltip" title={tooltip} />
}

export const CopyrightIcon = ({ className, tooltip }: IconProps) => {
    return <span className={`${className} sl-fa-button far fa-fw fa-copyright`} data-toggle="tooltip" title={tooltip} />
}

export const ShareIcon = ({ className, tooltip }: IconProps) => {
    return <span className={`${className} sl-fa-button fas fa-fw fa-users`} data-toggle="tooltip" title={tooltip} />
}

export const QuoteIcon = ({ className, tooltip }: IconProps) => {
    return (
        <span className={`${className} sl-fa-button fas fa-fw fa-quote-left`} data-toggle="tooltip" title={tooltip} />
    )
}

export const ApproveIcon = ({ className, tooltip }: IconProps) => {
    return <span className={`${className} sl-fa-button fas fa-fw fa-thumbs-up`} data-toggle="tooltip" title={tooltip} />
}
export const MembersIcon = ({ className, tooltip }: IconProps) => {
    return <span className={`${className} sl-fa-button fas fa-fw fa-user`} data-toggle="tooltip" title={tooltip} />
}

export const ProjectGroupsIcon = ({ className, tooltip }: IconProps) => {
    return <span className={`${className} sl-fa-button fas fa-sitemap`} data-toggle="tooltip" title={tooltip} />
}

export const DisapproveIcon = ({ className, tooltip }: IconProps) => {
    return (
        <span className={`${className} sl-fa-button fas fa-fw fa-thumbs-down`} data-toggle="tooltip" title={tooltip} />
    )
}

export const DragAndDropIcon = ({ className, tooltip }: IconProps) => {
    const { t } = useTranslation()
    const title = tooltip ?? t('Drag to reorder.')

    return <span className={`${className} fas fa-grip-vertical`} data-toggle="tooltip" title={title} />
}

export const ExpandIcon = ({ className, tooltip }: IconProps) => {
    return (
        <span className={`${className} sl-fa-button fas fa-fw fa-expand-alt`} data-toggle="tooltip" title={tooltip} />
    )
}

export const TableIcon = ({ className, tooltip }: IconProps) => {
    return <span className={`${className} fa-solid fa-table`} data-toggle="tooltip" title={tooltip} />
}

export const PencilIcon = ({ className, tooltip, enabled }: IconProps) => {
    return (
        <span
            className={enable(`${className} sl-fa-button fas fa-pencil-alt`, enabled)}
            data-toggle="tooltip"
            title={tooltip}
        />
    )
}

export const CloneIcon = ({ className, tooltip }: IconProps) => {
    return <span className={`${className} sl-fa-button fas fa-clone`} data-toggle="tooltip" title={tooltip} />
}

export const GoBackIcon = ({ className, tooltip }: IconProps) => {
    return (
        <span className={`${className} sl-fa-button fas fa-angle-double-left`} data-toggle="tooltip" title={tooltip} />
    )
}

export const GoForwardIcon = ({ className, tooltip }: IconProps) => {
    return (
        <span className={`${className} sl-fa-button fas fa-angle-double-right`} data-toggle="tooltip" title={tooltip} />
    )
}

export const EditIcon = ({ className, tooltip }: IconProps) => {
    return <span className={`${className} sl-fa-button fas fa-edit`} data-toggle="tooltip" title={tooltip} />
}

export const UploadIcon = ({ className, tooltip }: IconProps) => {
    return (
        <span className={`${className} sl-fa-button fas fa-cloud-upload-alt`} data-toggle="tooltip" title={tooltip} />
    )
}

export const CloudDownloadIcon = ({ className, tooltip }: IconProps) => {
    return (
        <span className={`${className} sl-fa-button fas fa-cloud-download-alt`} data-toggle="tooltip" title={tooltip} />
    )
}

export const FileUploadIcon = ({ className, tooltip }: IconProps) => {
    return <span className={`${className} sl-fa-button fas fa-upload`} data-toggle="tooltip" title={tooltip} />
}

export const ConsultantIcon = ({ className, tooltip }: IconProps) => {
    return <span className={`${className} sl-fa-button fa fa-fw fa-user-check`} data-toggle="tooltip" title={tooltip} />
}

export const GotoReferenceIcon = ({ className, tooltip, dir }: IconProps) => {
    return (
        <span
            className={`${className} sl-fa-button fa fa-fw ${
                dir === 'rtl' ? 'fa-arrow-circle-left' : 'fa-arrow-circle-right'
            }`}
            data-toggle="tooltip"
            title={tooltip}
        />
    )
}

export const CogIcon = ({ className, tooltip }: IconProps) => {
    return <span className={`${className} sl-fa-button fas fa-cog`} data-toggle="tooltip" title={tooltip} />
}

export const UsersCogIcon = ({ className, tooltip }: IconProps) => {
    return <span className={`${className} sl-fa-button fas fa-users-cog`} data-toggle="tooltip" title={tooltip} />
}

interface CircleIconProps {
    className: string
    style: any
    tooltip: string
}

export const CircleIcon = ({ className, style, tooltip }: CircleIconProps) => {
    return (
        <span
            className={`${className} sl-fa-button fas fa-fw fa-circle`}
            style={style}
            data-toggle="tooltip"
            title={tooltip}
        />
    )
}

export const ThinCircleIcon = ({ className, tooltip, style }: IconProps) => {
    return (
        <span
            className={`${className} sl-fa-button far fa-fw fa-circle`}
            data-toggle="tooltip"
            title={tooltip}
            style={style}
        />
    )
}

export const CheckCircle = ({ className, tooltip, style }: IconProps) => {
    return (
        <span
            className={`${className} sl-fa-button far fa-fw fa-check-circle`}
            data-toggle="tooltip"
            title={tooltip}
            style={style}
        />
    )
}

export const SquareIcon = ({ className, tooltip }: IconProps) => {
    return <span className={`${className} sl-fa-button far fa-square`} data-toggle="tooltip" title={tooltip} />
}

export const ForwardIcon = ({ className, tooltip }: IconProps) => {
    return <span className={`${className} sl-fa-button fas fa-forward`} data-toggle="tooltip" title={tooltip} />
}

interface GenericIconProps {
    iconName: string
    iconType?: 'fas' | 'far'
    className: string
    tooltip: string
    enabled?: boolean
}

// iconName, e.g. fa-flag
export const GenericIcon = ({
    iconName,
    iconType = 'fas',
    className = '',
    tooltip = '',
    enabled = true
}: GenericIconProps) => {
    return (
        <span
            className={enable(`${className} generic-icon ${iconType} ${iconName}`, enabled)}
            data-toggle="tooltip"
            title={tooltip}
        />
    )
}

export const CheckIcon = ({ className, tooltip }: Omit<Omit<GenericIconProps, 'iconType'>, 'iconName'>) => {
    return <GenericIcon iconName="fa-check" className={className} tooltip={tooltip} />
}

export const UpdateAvailableIcon = () => {
    const { t } = useTranslation()

    return (
        <GenericIcon
            iconName="fa-rotate"
            className="download-progress-icon book-download-button"
            iconType="fas"
            tooltip={t('An update is available.')}
        />
    )
}

export const DocumentIcon = ({ className, tooltip }: IconProps) => {
    return <span className={`${className} sl-fa-button far fa-file-alt`} data-toggle="tooltip" title={tooltip} />
}

export const DownloadIcon = ({ className, tooltip }: IconProps) => {
    return <span className={`${className} sl-fa-button fas fa-download`} data-toggle="tooltip" title={tooltip} />
}

export const ClipboardIcon = ({ className, tooltip }: IconProps) => {
    return <span className={`${className} sl-fa-button far fa-clipboard`} data-toggle="tooltip" title={tooltip} />
}

export const TrashIcon = ({ className, tooltip }: IconProps) => {
    return (
        <span
            className={`${className} sl-fa-button fas fa-trash-alt`}
            data-id="trash-button"
            data-toggle="tooltip"
            title={tooltip}
        />
    )
}

export const PublishedBiblesIcon = ({ className, tooltip }: IconProps) => {
    return (
        <SlttHelp id="bibles" tooltip={tooltip} place="top">
            <span className={`${className} sl-fa-button fas fa-bible`} />
        </SlttHelp>
    )
}

export const ExegeticalResourcesIcon = ({ className, tooltip }: IconProps) => {
    return (
        <SlttHelp id="exegetical-resources" tooltip={tooltip} place="top">
            <span className={`${className} sl-fa-button fas fa-book-open`} />
        </SlttHelp>
    )
}

export const HelpLink = ({ className, tooltip }: IconProps) => {
    const { i18n } = useTranslation()
    return (
        <a href={helpDocUrl(i18n.language)} target="_blank" rel="noreferrer">
            <span className={`${className} fas fa-question-circle`} data-toggle="tooltip" title={tooltip} />
        </a>
    )
}

export const CopyIcon = ({ className, tooltip }: IconProps) => {
    return <span className={`${className} sl-fa-button far fa-copy`} data-toggle="tooltip" title={tooltip} />
}

export const EnvelopeIcon = ({ className, tooltip }: IconProps) => {
    return <span className={`${className} sl-fa-button fas fa-envelope`} data-toggle="tooltip" title={tooltip} />
}

export const StarIcon = ({ className, tooltip }: IconProps) => {
    return <span className={`${className} sl-fa-button fas fa-star`} data-toggle="tooltip" title={tooltip} />
}

export const HeadphonesIcon = ({ className, tooltip }: IconProps) => {
    return <span className={`${className} sl-fa-button fas fa-headphones`} data-toggle="tooltip" title={tooltip} />
}

export const PDFIcon = ({ className, tooltip }: IconProps) => {
    return <span className={`${className} sl-fa-button far fa-file-pdf`} data-toggle="tooltip" title={tooltip} />
}

export const NoInternetIcon = ({ className }: IconProps) => {
    return (
        <span className={`${className} fa-stack fa-2x`}>
            <span className="fas fa-wifi fa-stack-1x" />
            <span className="ban-icon fas fa-ban fa-stack-2x" />
        </span>
    )
}

export const FolderIcon = ({ className, tooltip }: IconProps) => {
    return <span className={`${className} sl-fa-button far fa-folder`} data-toggle="tooltip" title={tooltip} />
}

export const PassageResourcesIcon = ({ className, tooltip }: IconProps) => {
    return (
        <SlttHelp id="passages-resources" tooltip={tooltip} place="top">
            <FolderIcon className={className} tooltip="" />
        </SlttHelp>
    )
}

export const VerticalSplitIcon = ({ className, tooltip }: IconProps) => {
    return (
        <svg viewBox="0 0 40 40" className={className}>
            <title>{tooltip}</title>
            <g>
                <line x1="0" y1="2" x2="40" y2="2" strokeWidth="4" stroke="black" />
                <line x1="2" y1="0" x2="2" y2="40" strokeWidth="4" stroke="black" />
                <line x1="0" y1="38" x2="40" y2="38" strokeWidth="4" stroke="black" />
                <line x1="38" y1="40" x2="38" y2="0" strokeWidth="4" stroke="black" />
                <line x1="20" y1="0" x2="20" y2="40" strokeWidth="4" stroke="black" />
            </g>
        </svg>
    )
}

export const HorizontalSplitIcon = ({ className, tooltip }: IconProps) => {
    return (
        <svg viewBox="0 0 40 40" className={className}>
            <title>{tooltip}</title>
            <g>
                <line x1="0" y1="2" x2="40" y2="2" strokeWidth="4" stroke="black" />
                <line x1="2" y1="0" x2="2" y2="40" strokeWidth="4" stroke="black" />
                <line x1="0" y1="38" x2="40" y2="38" strokeWidth="4" stroke="black" />
                <line x1="38" y1="40" x2="38" y2="0" strokeWidth="4" stroke="black" />
                <line x1="0" y1="20" x2="40" y2="20" strokeWidth="4" stroke="black" />
            </g>
        </svg>
    )
}

export const EditDifficultyIcon = CogIcon

export const EllipsisIcon = ({ className, tooltip }: IconProps) => {
    return <span className={`${className} fas fa-ellipsis-h`} data-toggle="tooltip" title={tooltip} />
}
