/* eslint-disable react/jsx-props-no-spreading */
import { useTranslation } from 'react-i18next'
import ReactSelect, { GroupBase, OptionsOrGroups, Props } from 'react-select'

import './Select.css'

interface SelectProps<Option, IsMulti extends boolean = false, Group extends GroupBase<Option> = GroupBase<Option>>
    extends Props<Option, IsMulti, Group> {
    selectAllOption?: Option extends { label: string; value: string } ? Option : never
}

const Select = <Option, IsMulti extends boolean = false, Group extends GroupBase<Option> = GroupBase<Option>>(
    props: SelectProps<Option, IsMulti, Group>
) => {
    const {
        className = '',
        classNamePrefix = '',
        isMulti = false,
        options: optionsProps = [],
        placeholder,
        selectAllOption,
        value: valueProps
    } = props
    const { t } = useTranslation()
    const options: OptionsOrGroups<Option, Group> | undefined =
        isMulti && selectAllOption ? [selectAllOption, ...optionsProps] : optionsProps

    const isAllSelected =
        Array.isArray(valueProps) &&
        valueProps.length &&
        selectAllOption &&
        (valueProps.some((option) => option.value === selectAllOption.value) ||
            optionsProps.length === valueProps.length)

    const value = isAllSelected ? [selectAllOption] : valueProps

    return (
        <ReactSelect
            {...props}
            className={`${className} select`}
            classNamePrefix={`${classNamePrefix} select-prefix`}
            options={options}
            placeholder={
                !placeholder && isMulti ? t('selectOneOrMore') : !placeholder && !isMulti ? t('selectOne') : placeholder
            }
            value={value}
        />
    )
}

export default Select
