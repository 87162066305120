import { observable } from 'mobx'

import { DBObject } from './DBObject'
import { Project } from './Project'
import { IVideoDownloadQuery } from './VideoCacheDownloader'
import { fmt } from '../components/utils/Fmt'
import { currentTimestampSafeString } from '../components/utils/Helpers'
import { shouldEncodeOpus, encodeOpus } from '../components/utils/Opus'
import { MAX_RICHTEXT_SIZE } from '../components/utils/RichTextEditor'
import { DbObjectIdPrefix, TextHistoryEntry } from '../types'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const log = require('debug')('sltt:Models')

interface IVideoDownloader {
    queryVideoDownload: (_id: string) => Promise<IVideoDownloadQuery>
}

// Large documents are stored in S3 since they are too big to store in DynamoDB.
const loadText = async (_text: string, videoDownloader: IVideoDownloader): Promise<string> => {
    const timeout = (ms: number) =>
        new Promise((res) => {
            setTimeout(res, ms)
        })

    while (true) {
        const response = await videoDownloader.queryVideoDownload(_text.slice(3))
        if (response.blob) {
            const downloadedText = await response.blob.text()
            return downloadedText
        }

        await timeout(250)
    }
}

export const getText = async (text: string, videoDownloader: IVideoDownloader) => {
    return text.startsWith('s3:') ? loadText(text, videoDownloader) : Promise.resolve(text)
}

const ACCEPTOR_VERSION = 27

export class PassageDocument extends DBObject {
    // If text starts with 's3:' then it is the url of the document in S3
    @observable text = ''

    @observable pdfUrl = ''

    @observable audioUrl = ''

    @observable title = ''

    @observable textHistory: TextHistoryEntry[] = []

    toDocument(useExistingModDate?: boolean, useExistingModBy?: boolean) {
        const { title, text, audioUrl, pdfUrl } = this
        return this._toDocument(
            { title, text, audioUrl, pdfUrl, model: ACCEPTOR_VERSION },
            useExistingModDate,
            useExistingModBy
        )
    }

    copy() {
        let copy = new PassageDocument(this._id, this.db)
        copy = Object.assign(copy, this)
        return copy
    }

    get isGlobal() {
        return this._id.includes(DbObjectIdPrefix.PROJECT_DOCUMENT)
    }

    // Set text. If long push to s3 and make text be a reference to that bucket.
    async setText(text: string, projectName: string, modBy?: string, modDate?: string) {
        log('PassageDocument setText', fmt({ _id: this._id, text, projectName }))

        text = text.trim()
        if (this.text === text) {
            return
        }

        if (text.length >= MAX_RICHTEXT_SIZE) {
            const blob = new Blob([text], { type: 'text/plain' })
            const s3Path = `${projectName}/${this._id}/${currentTimestampSafeString()}.txt`
            log('s3Path', s3Path)

            const url = await Project.copyFileToVideoCache(blob as File, s3Path, true)

            text = `s3:${url}`
        }

        const doc = this._toDocument({ text, model: ACCEPTOR_VERSION })

        if (modBy) {
            doc.modBy = modBy
        }
        if (modDate) {
            doc.modDate = modDate
        }
        await this.db.put(doc)
    }

    async setTitle(newTitle: string) {
        log('PassageDocument setTitle', fmt({ _id: this._id, newTitle }))

        const title = newTitle.trim()
        if (this.title === title) {
            return
        }

        const doc = this._toDocument({ title, model: ACCEPTOR_VERSION })
        await this.db.put(doc)
    }

    async uploadAudioFile(file: File, projectName: string) {
        if (!file.type.startsWith('audio/')) {
            return
        }

        if (!Project.copyFileToVideoCache) {
            throw new Error('Project.copyFileToVideoCache not set')
        }

        // base url of an audio file is stored with mp3 at the end, for backward compatibility
        const baseUrl = `${projectName}/${this._id}/${currentTimestampSafeString()}.mp3`
        const fileToUpload = shouldEncodeOpus(file.name) ? await encodeOpus(file) : file
        const url = await Project.copyFileToVideoCache(fileToUpload, baseUrl, true)
        await this.setAudioUrl(url)
    }

    async uploadPDFFile(file: File, projectName: string) {
        if (!file.type.startsWith('application/pdf')) {
            return
        }

        if (!Project.copyFileToVideoCache) {
            throw new Error('Project.copyFileToVideoCache not set')
        }

        const baseUrl = `${projectName}/${this._id}/${currentTimestampSafeString()}.pdf`
        const url = await Project.copyFileToVideoCache(file, baseUrl, true)
        await this.setPdfUrl(url)
    }

    async setAudioUrl(audioUrl: string) {
        if (audioUrl === this.audioUrl) {
            return
        }
        const doc = this._toDocument({ audioUrl, model: ACCEPTOR_VERSION })
        await this.db.put(doc)
    }

    async setPdfUrl(pdfUrl: string) {
        if (pdfUrl === this.pdfUrl) {
            return
        }
        const doc = this._toDocument({ pdfUrl, model: ACCEPTOR_VERSION })
        await this.db.put(doc)
    }
}
