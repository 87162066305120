// SegmentToolbar allows controlling playing and recording
import { useEffect } from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { OldSegmentDropdown } from './OldSegmentDropdown'
import { SegmentApproval } from './SegmentApproval'
import SegmentCutButton from './SegmentCutButton'
import { PassageSegment } from '../../models3/PassageSegment'
import { PassageVideo } from '../../models3/PassageVideo'
import { Root } from '../../models3/Root'
import { RecordingType } from '../../types'
import { PatchFilePicker } from '../filePickers/BaseRecordingFilePicker'
import { PlayButton, PauseButton, RecordButton, StopButton, SlttHelp, GenericIconButton } from '../utils/Buttons'
import { clamp, decrementTime, isWithinTolerance } from '../utils/Helpers'
import { PatchVideoDeleter } from '../video/PatchVideoDeleter'
import { AVTTRecordingState } from '../video/VideoRecorder'
import '../utils/Buttons.css'

interface ISegmentToolbar {
    rt: Root
    segment: PassageSegment
    oldVersionOptions: PassageVideo[]
    isAppendedSegment: boolean
    chooseVersionToPlay: (id: string) => void
    play: () => void
    pause: () => void
    record: (recordingType: RecordingType) => void
    stopRecording: () => void
    baseSegment: PassageSegment
    className: string
    recordingState: AVTTRecordingState
    isNotPlayingNorRecording: boolean
    isInReview: boolean
    recordEnabled: boolean
}

const SegmentToolbar = observer(
    ({
        rt,
        record,
        stopRecording,
        chooseVersionToPlay,
        oldVersionOptions,
        isAppendedSegment,
        play,
        pause,
        segment,
        className,
        recordingState,
        isNotPlayingNorRecording,
        isInReview,
        recordEnabled
    }: ISegmentToolbar) => {
        const { t } = useTranslation()
        const {
            playing,
            passage,
            passageVideo,
            passageSegment,
            recording,
            iAmTranslatorForPassage,
            canPlayThrough,
            timeline,
            project
        } = rt

        if (!passage || !passageSegment || !passageVideo) {
            return null
        }

        const isSelectionPresent = timeline.selectionPresent()
        const patchableSelectionPresent = rt.patchableSelectionPresent()

        const enabled = !passage.videoBeingCompressed
        const playEnabled = canPlayThrough && !recording && enabled

        let recordTooltip = t('Record patch for this segment.')
        if (isSelectionPresent) {
            recordTooltip = t('Record patch for the selection.')
            if (!patchableSelectionPresent) {
                recordTooltip += ` ${t('Patch selections cannot cross segments boundaries.')}`
            }
        }

        const stopEnabled = enabled && (recordingState === 'RECORDING' || recordingState === 'PAUSED')

        // When the segment is hidden (i.e., the user appended the recording), do not include
        // the first patch, since it functions as the original recording
        const oldSegmentDropdownShown = passageSegment.videoPatchHistory.length > (passageSegment.isHidden ? 1 : 0)

        const playButtonClass = `sl-segment-toolbar-button`

        const actualSegment = passageSegment.actualSegment(passage)

        // make these a little smaller so we know they are in the current segment
        const timeTillEndOfSegment = decrementTime(actualSegment.endTime - rt.currentTime)
        const timeSinceStartOfSegment = decrementTime(rt.currentTime - actualSegment.time)
        const timeToMoveForward = clamp(timeTillEndOfSegment, 0, 1)
        const timeToMoveBackward = clamp(timeSinceStartOfSegment, 0, 1)

        const forwardEnabled = enabled && !playing && !isWithinTolerance(timeToMoveForward, 0) && !isSelectionPresent
        const backwardsEnabled = enabled && !playing && !isWithinTolerance(timeToMoveBackward, 0) && !isSelectionPresent

        const goForwardOneSecond = () => {
            if (forwardEnabled) {
                rt.adjustCurrentTime(timeToMoveForward)
            }
        }

        const goBackOneSecond = () => {
            if (backwardsEnabled) {
                rt.adjustCurrentTime(-1 * timeToMoveBackward)
            }
        }

        const handleKeyDown = (e: KeyboardEvent) => {
            if (!e.ctrlKey && !e.altKey) {
                return
            }

            const handlePlayPause = () => {
                if (playing) {
                    pause()
                } else {
                    play()
                }
            }

            switch (e.code) {
                case 'ArrowRight':
                    goForwardOneSecond()
                    break
                case 'ArrowLeft':
                    goBackOneSecond()
                    break
                case 'Space':
                    handlePlayPause()
                    break
                case 'KeyP':
                    // On macOS, ctrl + alt + space can trigger the mac language switcher, so provide an alternative way to trigger play/pause
                    handlePlayPause()
                    break
                default:
                    break
            }
        }

        useEffect(() => {
            window.addEventListener('keydown', handleKeyDown)

            return () => {
                window.removeEventListener('keydown', handleKeyDown)
            }
        })

        return (
            <div className={`segment-toolbar ${className}`}>
                <div className="segment-toolbar-first-group">
                    {playing && (
                        <>
                            <PauseButton
                                className="sl-segment-toolbar-button"
                                enabled={enabled}
                                tooltip={t('Pause.')}
                                onClick={pause}
                            />
                            {oldSegmentDropdownShown && (
                                <SlttHelp id="patches" tooltip={t('Play a previous version this segment.')} place="top">
                                    <OldSegmentDropdown
                                        dateFormatter={rt.dateFormatter}
                                        options={oldVersionOptions}
                                        enabled={false}
                                        onSelect={chooseVersionToPlay}
                                        isAppendedSegment={isAppendedSegment}
                                    />
                                </SlttHelp>
                            )}
                            <GenericIconButton
                                iconName="fa-rotate-left"
                                iconType="fas"
                                className={playButtonClass}
                                enabled={false}
                                onClick={() => {}}
                                tooltip={t('goBack1Second')}
                            />
                            <GenericIconButton
                                iconName="fa-rotate-right"
                                iconType="fas"
                                className={playButtonClass}
                                enabled={false}
                                onClick={() => {}}
                                tooltip={t('goForward1Second')}
                            />
                            <SlttHelp id="patches" tooltip={recordTooltip} place="top">
                                <RecordButton
                                    tooltip=""
                                    className="sl-segment-toolbar-button sl-record-patch-button"
                                    enabled={false}
                                    selectionPresent={isSelectionPresent}
                                    onClick={() => {}}
                                />
                            </SlttHelp>
                        </>
                    )}
                    {!playing && !recording && (
                        <>
                            <span className="segment-toolbar-play-group">
                                <PlayButton
                                    tooltip={isSelectionPresent ? t('Play selection.') : t('Play')}
                                    className={playButtonClass}
                                    enabled={playEnabled}
                                    selectionPresent={isSelectionPresent}
                                    onClick={play}
                                />
                                {oldSegmentDropdownShown && (
                                    <SlttHelp
                                        id="patches"
                                        tooltip={t('Play a previous version this segment.')}
                                        place="top"
                                    >
                                        <OldSegmentDropdown
                                            dateFormatter={rt.dateFormatter}
                                            options={oldVersionOptions}
                                            enabled={!!enabled}
                                            onSelect={chooseVersionToPlay}
                                            isAppendedSegment={isAppendedSegment}
                                        />
                                    </SlttHelp>
                                )}
                                <GenericIconButton
                                    iconName="fa-rotate-left"
                                    iconType="fas"
                                    className={playButtonClass}
                                    enabled={backwardsEnabled}
                                    onClick={goBackOneSecond}
                                    tooltip={t('goBack1Second')}
                                />
                                <GenericIconButton
                                    iconName="fa-rotate-right"
                                    iconType="fas"
                                    className={playButtonClass}
                                    enabled={forwardEnabled}
                                    onClick={goForwardOneSecond}
                                    tooltip={t('goForward1Second')}
                                />
                            </span>
                            <SlttHelp id="patches" tooltip={recordTooltip} place="top">
                                <RecordButton
                                    tooltip=""
                                    className="sl-segment-toolbar-button sl-record-patch-button"
                                    enabled={recordEnabled}
                                    selectionPresent={isSelectionPresent}
                                    onClick={() => {
                                        record(RecordingType.PATCH)
                                    }}
                                />
                            </SlttHelp>
                        </>
                    )}
                    {recording && (
                        <StopButton
                            className="sl-segment-toolbar-button segment-stop-button"
                            enabled={stopEnabled}
                            onClick={stopRecording}
                            tooltip={t('Stop recording.')}
                        />
                    )}
                    <PatchFilePicker
                        enabled={recordEnabled}
                        rt={rt}
                        passage={passage}
                        className="sl-segment-toolbar-button patch-upload-file-button"
                    />

                    {!recording && passageSegment.videoPatchHistory.length > 0 && (
                        <PatchVideoDeleter
                            {...{
                                enabled:
                                    enabled &&
                                    iAmTranslatorForPassage(passage) &&
                                    isNotPlayingNorRecording &&
                                    !isInReview &&
                                    !isSelectionPresent,
                                deleter: { segment: passageSegment, rt, passage, passageVideo }
                            }}
                        />
                    )}

                    <SegmentCutButton
                        isInReview={isInReview}
                        isNotPlayingNorRecording={isNotPlayingNorRecording}
                        isSelectionPresent={isSelectionPresent}
                        project={project}
                        rt={rt}
                    />
                </div>

                <div className="segment-toolbar-middle-group">
                    <SegmentApproval {...{ enabled: !!enabled && isNotPlayingNorRecording, segment }} />
                </div>

                <div className="segment-toolbar-last-group" />
            </div>
        )
    }
)

export default SegmentToolbar
