import React, { FC, useEffect, useState } from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import VideoDownloading from './VideoDownloading'

interface ILoadedThumbnail {
    isImage: boolean
    src: string
    onClickImage: () => void
    onClickVideo: () => void
    currentTime: number
}

const LoadedThumbnail: FC<ILoadedThumbnail> = observer(({ isImage, src, onClickImage, onClickVideo, currentTime }) => {
    const { t } = useTranslation()

    const videoRef = React.createRef<HTMLVideoElement>()

    useEffect(() => {
        if (videoRef.current) {
            videoRef.current.currentTime = currentTime
        }
    }, [currentTime, videoRef])

    if (isImage) {
        return (
            <img
                src={src}
                alt={t('Click to see larger version of image')}
                className="note-image"
                onClick={onClickImage}
            />
        )
    }

    return (
        <video
            ref={videoRef}
            className="note-video"
            preload="auto"
            onClick={onClickVideo}
            onCanPlayThrough={() => {
                if (videoRef.current && Math.abs(videoRef.current.currentTime - currentTime) >= 0.05) {
                    videoRef.current.currentTime = currentTime
                }
            }}
            src={src}
        />
    )
})

interface IMediaThumbnail {
    url: string
    creator: string
    isImage: boolean
    currentTime: number
    onClickImage: () => void
    onClickVideo: () => void
}

const MediaThumbnail: FC<IMediaThumbnail> = observer(
    ({ url, creator, isImage, currentTime, onClickVideo, onClickImage }) => {
        const [src, setSrc] = useState('')

        useEffect(() => {
            setSrc('')
        }, [url])

        useEffect(() => {
            return () => {
                if (src) {
                    window.URL.revokeObjectURL(src)
                }
            }
        }, [src])

        if (!url) {
            return null
        }

        if (src) {
            return (
                <div className="fill-area" data-id="loaded-thumbnail">
                    <LoadedThumbnail {...{ isImage, src, currentTime, onClickVideo, onClickImage }} />
                </div>
            )
        }

        return (
            <VideoDownloading
                className="media-placeholder"
                videoUrl={url}
                creator={creator}
                fontSizePt={30}
                onEnded={(blob) => setSrc(window.URL.createObjectURL(blob))}
            />
        )
    }
)

export default MediaThumbnail
