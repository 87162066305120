import { TFunction } from 'i18next'
import { observer } from 'mobx-react'
import { Dropdown } from 'react-bootstrap'
import { confirmAlert } from 'react-confirm-alert'
import { useTranslation } from 'react-i18next'

import API from '../../models3/API'
import { Root } from '../../models3/Root'
import { useOnlineStatus } from '../app/OnlineStatusContext'
import { AudioEncodeType, getPassageAudio } from '../utils/DownloadPassage'
import { getVerseTimeCodes } from '../utils/Engage'
import { displayError, systemError } from '../utils/Errors'
import { currentTimestampSafeString } from '../utils/Helpers'
import { GenericIcon } from '../utils/Icons'

const designateRecordingForReview = async (rt: Root, t: TFunction, checkIsOnline: () => Promise<boolean>) => {
    const { project, passage, passageVideo, name } = rt
    const { reviewProject, setUpProjectReview } = project

    if (!passage || !passageVideo) {
        return
    }

    try {
        const isOnline = await checkIsOnline()
        if (!isOnline) {
            displayError(t('recordingStartANewReviewErrorOffline'))
            return
        }

        passage.setCompressionProgressMessage(t('Initializing...'))
        await setUpProjectReview()

        if (!reviewProject) {
            passage.setCompressionProgressMessage('')
            return
        }

        passage.setCompressionProgressMessage(t('Starting compression...'))
        const { blob: concatenatedBlob } = await getPassageAudio(passage, passageVideo, AudioEncodeType.mp3)
        if (!concatenatedBlob) {
            return
        }

        const blob =
            concatenatedBlob instanceof File
                ? new Blob([concatenatedBlob], { type: concatenatedBlob.type })
                : concatenatedBlob

        const reviewRecording = reviewProject.createReviewRecording(new Date())
        const fileName = `${reviewRecording._id.split('/')[1]}-${currentTimestampSafeString()}.mp3`
        await API.pushReviewRecording(name, fileName, blob)

        reviewRecording.title = passageVideo.title ? `${passage.name} (${passageVideo.title})` : passage.name
        reviewRecording.passageRecordingId = passageVideo._id
        reviewRecording.fileName = fileName
        reviewRecording.duration = passageVideo.computedDuration
        reviewRecording.mimeType = passageVideo.mimeType
        reviewRecording.reference = rt.displayableReferences(passage.references)
        const { text, hasRealText } = passageVideo.getTranscription({ passage, project })
        reviewRecording.transcription = hasRealText.some((realText) => realText) ? text : ''
        reviewRecording.isActive = true

        const referenceMarkers = passageVideo.getVisibleReferenceMarkers(passage)
        reviewRecording.timeCodes = getVerseTimeCodes(referenceMarkers, passageVideo.computedDuration)
        await reviewProject.addPassageRecording(reviewRecording)
    } catch (err) {
        systemError(err)
    } finally {
        passage.setCompressionProgressMessage('')
    }
}

const handleReviewAction = (
    title: string,
    message: string,
    confirmLabel: string,
    t: TFunction,
    onConfirm: () => void
) => {
    confirmAlert({
        title,
        message,
        buttons: [
            {
                label: t('Cancel'),
                onClick: () => {}
            },
            {
                label: confirmLabel,
                onClick: onConfirm
            }
        ]
    })
}

export const VideoToolbarReviewMenuItems = observer(({ rt, className }: { rt: Root; className: string }) => {
    const { t } = useTranslation()
    const { isOnline, checkIsOnline } = useOnlineStatus()
    const { passage, passageVideo, iAmTranslatorForPassage } = rt
    const { isEngageEnabled, reviewProject } = rt.project

    const isInReview = passageVideo?.isInReview(reviewProject)

    if ((!isEngageEnabled && !isInReview) || !passageVideo?.isAudioOnly() || !reviewProject) {
        return null
    }

    const stopReview = () => {
        const latestReview = passageVideo?.getLatestReviewRecording(reviewProject)
        if (latestReview) {
            latestReview.setIsActive(false)
        }
    }

    const startReview = () => {
        designateRecordingForReview(rt, t, checkIsOnline)
    }

    const activeReviewsExistForPassage = passage?.videosNotDeleted.some((pv) => pv.isInReview(reviewProject))
    const enable = isOnline && iAmTranslatorForPassage(passage)
    const enableReview = enable && !activeReviewsExistForPassage

    const iconClassName = 'main-video-clipboard-icon default-blue-icon fa-fw'

    return isInReview ? (
        <Dropdown.Item
            className={className}
            disabled={!enable}
            onClick={() =>
                handleReviewAction(
                    t('recordingStopReview'),
                    t('recordingStopReviewConfirm'),
                    t('recordingStopReview'),
                    t,
                    stopReview
                )
            }
        >
            <GenericIcon
                iconName="fa-comments"
                className={iconClassName}
                tooltip={t('recordingStopReview')}
                enabled={enable}
            />
            {t('recordingStopReview')}
        </Dropdown.Item>
    ) : (
        <Dropdown.Item
            className={className}
            onClick={() =>
                handleReviewAction(
                    t('recordingStartANewReview'),
                    t('recordingStartANewReviewConfirm'),
                    t('recordingStartANewReview'),
                    t,
                    startReview
                )
            }
            disabled={!enableReview}
        >
            <GenericIcon
                iconName="fa-comments"
                className={iconClassName}
                tooltip={t('recordingStartANewReview')}
                enabled={enableReview}
            />
            {t('recordingStartANewReview')}
        </Dropdown.Item>
    )
})
